<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

      <!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Nueva Incidencia'][session.user.language] }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewIncidenceModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT New Incidence -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row p-0 m-0 justify-content-around">
          <div class="col-12 p-2 pe-0 pb-0">
            <form class="p-0 overflow-hidden pe-2 pb-3">
              <div class="row col-12 m-0 p-2 border rounded-5 overflow-hidden">
                <div class="row col-12 bg-menu text-white p-1 m-0 rounded">
                  <div class="row col-lg-6 col-12 p-0 m-0">
                    <label class="pt-1 text-start">
                      {{ dictionary['Datos de la incidencia'][session.user.language] }}
                    </label>
                  </div>
                  <div class="row col-lg-6 col-12 p-0 m-0 pe-3 justify-content-end">
                    <button type="button" @click="onCreateNewIncidence()" id="btnSubmitCreateNewProduct"
                        class="button-ok gradient-tablas rounded-3" style="max-height: 34px; max-width: 200px;">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0">{{ dictionary['Crear incidencia'][session.user.language] }}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                <div class="row col-12 p-0 m-0">
                  <!-- NOMBRE -->
                <div class="form-group col-md-4 col-12 p-2">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Nombre'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <input v-model="newIncidenceData.label" id="inputNewIncidenceLabel" type="text"
                        class="form-control form-control-line" style="color:black;">
                    </div>
                </div>
                  
                  <!-- roles -->
                <div class="form-group col-md-4 col-12 p-2">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Nivel de gravedad'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="newIncidenceData.level" id="inputNewIncidenceLevel"
                        class="form-control form-control-line" style="color:black;">
                        <option value="0">
                          {{ dictionary['Muy baja'][session.user.language] }}
                        </option>
                        <option value="1">
                          {{ dictionary['Baja'][session.user.language] }}
                        </option>
                        <option value="2">
                          {{ dictionary['media'][session.user.language] }}
                        </option>
                        <option value="3">
                          {{ dictionary['Alta'][session.user.language] }}
                        </option>
                        <option value="4">
                          {{ dictionary['Critica'][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-12 p-2">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Estado envio aviso'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="newIncidenceData.status" id="inputNewIncidenceStatus"
                        class="form-control form-control-line" style="color:black;">
                        <option value="">
                          Ninguno
                        </option>
                        <option value=0>
                          {{ dictionary["Abierta"][session.user.language] }}
                        </option>
                        <option value=1>
                          {{ dictionary["En investigación"][session.user.language] }}
                        </option>
                        <option value=2>
                          {{ dictionary["En proceso de resolucion"][session.user.language] }}
                        </option>
                        <option value=3>
                          {{ dictionary["Solucionada"][session.user.language] }}
                        </option>
                        <option value=4>
                          {{ dictionary["Cerrada"][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-12 p-2">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Rol usuario aviso'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="newIncidenceData.rol" id="inputNewIncidenceRol"
                        class="form-control form-control-line" style="color:black;">
                        <option value="">
                          Ninguno
                        </option>
                        <option value=128>
                          {{ dictionary["Administrador de la empresa"][session.user.language] }}
                        </option>
                        <option value=32>
                          {{ dictionary["Autorizado/Editor"][session.user.language] }}
                        </option>
                        <option value=2>
                          {{ dictionary["Cliente"][session.user.language] }}
                        </option>
                        <option value=255>
                          {{ dictionary["Administrador"][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- mail -->
                  <div class="form-group col-md-4 col-12 p-2 justify-content-center">
                      <label class="col-12 text-clomos tx-14 tx-bold">
                        {{ dictionary['Aviso email'][session.user.language] }}
                      </label>
                      <div class="col-12">
                        <div id="inputNewIncidencemail" class="form-control border-0 tx-bold w-100"
                          :class="newIncidenceData.email.toString()"
                          @click="(newIncidenceData.email === true) ? newIncidenceData.email = false : newIncidenceData.email = true">
                          {{ dictionary[newIncidenceData.email][session.user.language] }}
                        </div>
                      </div>
                  </div>
                  <div class="form-group col-md-4 col-12 p-2 rounded-5">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                        {{ dictionary['Tiempo de repetición del aviso'][session.user.language] }}
                    </label>
                    <div class= "col-12">
                      <select v-model="newIncidenceData.repetitionTime"
                        class="form-control form-control-line" style="color:black;">
                        <option value="0" selected>
                          {{ dictionary['Nunca'][session.user.language] }}
                        </option>
                        <option value="21600">
                          {{ dictionary['6 horas'][session.user.language] }}
                        </option>
                        <option value="43200">
                          {{ dictionary['12 horas'][session.user.language] }}
                        </option>
                        <option value="86400">
                          {{ dictionary['24 horas'][session.user.language] }}
                        </option>
                       <option value="604800">
                          {{ dictionary['Una semana'][session.user.language] }}
                        </option>
                      </select>
                  </div>
                </div>
              <div class="row form-group col-md-12 col-12 p-2 ps-4">
                    <label class="col-12 text-clomos ps-4 tx-14 tx-bold text-start">
                      {{ dictionary['Descripción'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <textarea v-model="newIncidenceData.description" id="inputNewIncidenceDescription" type="text"
                        rows="2" class="form-control form-control-line text-start" style="color:black;" />
                    </div>
                </div>
            </div>
          </div>
          </form>
          </div>
        </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewIncidenceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
 
  },
  data() {
    return {
      newIncidenceData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        label: "",
        description: "",
        rol: "",
        sms: "false",
        email: "false",
        level: "",
        days: null,
        minutes: null,
        seconds: null,
        repetitionTime: '0',
        status: '',
      },
    }
  },
  emits: ["toggleNewIncidenceModal"],
  methods: {
    async onCreateNewIncidence() {
      let valid = true;
      if (this.newIncidenceData.label === "") {
        $("#inputNewIncidenceLabel").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.description === "") {
        $("#inputNewIncidenceDescription").addClass("missing");
        valid = false;
      }
     /*  if (this.newIncidenceData.rol === "") {
        $("#inputNewIncidenceRol").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.status === "") {
        $("#inputNewIncidenceStatus").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.level === "") {
        $("#inputNewIncidenceLevel").addClass("missing");
        valid = false;
      } 
      if (this.newIncidenceData.days === null && this.newIncidenceData.minutes === null && this.newIncidenceData.seconds === null) {
        $("#inputNewIncidenceDays").addClass("missing"); ~
          $("#inputNewIncidenceMinutes").addClass("missing");
        $("#inputNewIncidenceSeconds").addClass("missing");
        valid = false;
      }*/
      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }





      try {
        let response = await api.postNewIncidence(this.newIncidenceData);
        if (response["status-code"] === "201") {
          this.$emit('toggleNewIncidenceModal');
          window.alert("La incidencia ha sido creado satisfactoriamente.");
        } else {
          window.alert("Error al crear la incidencia.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert("Error al crear la incidencia.");
      }
    },

  }
}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>