<template>
  <!-- SECTION Lockers-->
  <div id="lockersSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-6 col-lg-7 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select
                    v-if="this.$route.params.currentDisplay === 'lockers'  || this.targetEmplacementId == ''"
                    :value="currentCompany.nifCif" class="form-control m-0 p-0 text-nowrap bg-white border me-3"
                    style="border: solid 2px white ; color: black; min-width:110px; max-width: 300px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>
                <div class="col justify-content-start m-0 p-0 pt-1">
                  <button @click="onChangeDisplayType('map')" :title="dictionary['Mapa'][session.user.language]"
                    :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="map-outline"
                          :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="pt-0 tx-18 hydrated md" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                    :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="list-outline"
                          :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="documents-outline"
                          :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-9 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="file-tray-stacked-outline"
                      class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Lockers"][session.user.language]}}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-2 col-4 justify-content-start p-0 m-0 pt-1">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                    :title="dictionary['Añadir locker'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleNewLockerModal">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir locker'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>

                  <button v-if="editingPermission" id="btnDisplayEditEraseModal"
                    :title="dictionary['Editar locker'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleConfigurationView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="pt-0 me-0 tx-18 hydrated"
                          :title="dictionary['Editar locker'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" :title="dictionary['Actualizar'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="refreshView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="refresh-outline" class="pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- CONTENT Map -->
          <div v-show="!displayType.includes('map')" :class="fullSize ? 'col-lg-12' : 'col-lg-4'"
            class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1 c-pointer" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div id="lockersMapContent" class="col-12 bg-caja rounded-5 shadow"
              :style="displayType.includes('cards') && displayType.includes('table') ? 'min-height: 80vh; ' : 'min-height: 480px;'"
              style="z-index: 0; height: auto; border-top: solid 5px #005793; border-bottom: solid 2px #008db4; margin-top: -20px;">
            </div>
          </div>

          <!-- CARDS Lockers -->
          <div v-show="!displayType.includes('cards') && this.lockerList[0] != undefined" id="lockersCardsContent"
            :class="fullSize ? 'col-lg-12' : 'col-lg-8'" class="col-8 m-0 p-2 h-auto"
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1  c-pointer" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>

            <div class="row col-12 bg-caja rounded-5 shadow" :class="fullSize ? 'ms-1 me-3' : 'ms-0'"
              style="min-height: 480px; z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:-20px;">
              <div v-for="locker in lockerCardsByPages[lockerCardsPage]" :key="locker"
                class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6
                  class="tx-left tx-clomos-white text-center text-nowrap tx-11 m-0 p-2 ps-1 pt-2 bg-lat rounded-top-3">
                  <span id="cardLabel" :data-id="locker.id" class="m-auto c-pointer btnTargetLocker">{{
                    (locker.label.length > 18) ? (locker.label.substring(0, 18) + '...') : locker.label }}</span>
                </h6>

                <div class="row col-12 border-1 rounded-bottom-3 m-0 ps-2 pe-2 pt-1 pb-1">

                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 140px;">
                    <span class="tx-14 tx-bold">{{ dictionary['Tipo'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ locker.type }}</span>
                    <span class="tx-14 tx-bold">{{ dictionary['Posición'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{dictionary['Latitud'][session.user.language]+ ": "}}{{Number(locker.geoJSON.geometry.coordinates[0]) === 0? Number(locker.geoJSON.geometry.coordinates[0]) : Number(locker.geoJSON.geometry.coordinates[0]).toFixed(2) }}</span>
                    <span class="tx-11 tx-start">{{dictionary['Longitud'][session.user.language]+ ": "}}{{Number(locker.geoJSON.geometry.coordinates[1]) === 0? Number(locker.geoJSON.geometry.coordinates[1]) : Number(locker.geoJSON.geometry.coordinates[1]).toFixed(2) }}</span>
                    <span class="d-flex justify-content-between align-items-center pt-1 pb-1">
                      <ion-icon v-if="locker.openedIncidences!='0'" name="alert-circle-outline" :data-id="locker.id" :title="dictionary['Incidencias'][session.user.language]+': '+locker.openedIncidences"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                      <ion-icon v-if="locker.activeAlarms!='0'" name="warning-outline" :data-id="locker.id" :title="dictionary['Alarmas'][session.user.language]+': '+locker.activeAlarms"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page, index in lockerCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-14"
                  :class="(lockerCardsByPages === index) ? 'border-dark' : ''"
                  style="height:2.5rem; width:2.5rem; padding: inherit;" @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- CONTENT table -->

          <div id="lockersTableContent" v-show="!displayType.includes('table')"
            :style="fullHeight ? 'min-height: 80vh;' : 'min-height: 480px;'"
            :class="fullSize ? 'col-lg-12' : 'col-lg-12'" class="row col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja rounded-5 pt-2 shadow table-responsive"
              style=" z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:20px;">
              <table id="lockersDataTable" class="table table-striped pt-0 pb-3 bg-caja">
                <thead class="bg-lat tx-14 p-0 text-white text-center ">
                  <tr>
                    <th v-for="column in lockersColumns" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <div v-show="!displayType.includes('cards') && this.lockerList[0] == undefined"
            class="m-3 p-3 border rounded">
            <span class="tx-14">
              {{ dictionary['No hay lockers disponibles'][session.user.language] }}
            </span>
          </div>

        </div>
      </div>

    </div>
  </div>
    <div v-if="display.configurationView">
      <LockersConfigurationComponent :session="session" :dictionary="dictionary" :countries="countries"
        :timeZones="timeZones" :visibility="visibility" :currentCompany="currentCompany"
        :availableCompanies="availableCompanies" :targetEmplacementId="''" :dataTableOptions="dataTableOptions"
        v-on:toggleConfigurationView="toggleConfigurationView">
      </LockersConfigurationComponent>
    </div>

    <!-- MODAL New Locker -->
    <NewLockerModal v-if="display.NewLockerModal" :session="session" :dictionary="dictionary" :countries="countries"
      :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewLockerModal="toggleNewLockerModal">
    </NewLockerModal>



 
</template>

<script>
import $ from 'jquery';

import LockersConfigurationComponent from '@/components/lockers/LockersConfigurationComponent';
import NewLockerModal from '@/components/lockers/NewLockerModal';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// Clomos
import api from '@/services/api';

export default {
  name: "LockersComponent",
  components: { NewLockerModal, LockersConfigurationComponent },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getEmplacementList();
        await this.getLockersDataByCompany();       
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
      }

      this.$nextTick(() => {
          this.displayLockersMap();
          this.displayLockersDataTable();
        });
        this.fullSize = false;
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id
    },
    targetEmplacementId: async function () {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
        
      }
      this.$nextTick(() => {
          this.displayLockersMap();
        });
        this.fullSize = false;
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id;
    },
    fullSize: async function (newValue) {
      const currentDisplay = this.$route.params.currentDisplay;
      const module = this.$route.params.currentModule;
      let moduleData = JSON.parse(localStorage.getItem(module)) || {};
      moduleData[currentDisplay] = moduleData[currentDisplay] || {};
      moduleData[currentDisplay].fullSize = newValue;
      localStorage.setItem(module, JSON.stringify(moduleData));
    },
    displayType: {
      handler: async function (newValue) {
        
        if (newValue.includes("cards") && newValue.includes("map") && !newValue.includes("table")) {
          this.fullHeight = true;
        } else {
          this.fullHeight = false;
        }
        if (!newValue.includes("cards") && newValue.includes("map")) this.fullSize = true;
        const currentDisplay = this.$route.params.currentDisplay;
        const module = this.$route.params.currentModule;
        let moduleData = JSON.parse(localStorage.getItem(module)) || {};
        moduleData[currentDisplay] = moduleData[currentDisplay] || {};
        moduleData[currentDisplay].displayType = newValue;
        localStorage.setItem(module, JSON.stringify(moduleData));
      },
      deep: true
    },
  },
  data() {
    return {
      lockersColumns: [      
        {
          text: "Nombre", data: "locker", width: "15%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100  btnTargetLocker c-pointer'>" + data.label + "</span>"
          }
        },
        { text: "Tipo", data: "lockerType", width: "10%", className: "tx-left align-middle" },
        {
          text: "Emplazamiento", data: "lockerEmplacement", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.emplacementList.length > 0) {
              for (let emplacement in this.emplacementList)
                if (this.emplacementList[emplacement].id === data)
                  emplacementLabel = this.emplacementList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
      /*   {
          text: "Fecha de comunicación", data: "lockerCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        }, */
       /*  { text: "Consigna", data: "lockerConsinga", width: "5%", className: "tx-left align-middle" },
        { text: "Histéresis", data: "lockerHisteresis", width: "5%", className: "tx-left align-middle" }, */
        {
          text: "Alarmas", data: "locker", width: "15%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            const activeAlarms = data.activeAlarms==='0'? '-': data.activeAlarms
            const className = activeAlarms !== '-' ? "btnTargetLocker c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${activeAlarms}
            </span>`;
          }
        },
        {
          text: "Incidencias", data: "locker", width: "15%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            let openedIncidences = data.openedIncidences==='0'? '-': data.openedIncidences
            const className = openedIncidences !== '-' ? "btnTargetLocker c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${openedIncidences}
            </span>`;
          }
        },
        {
          text: "Posición", data: "locker", width: "15%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            const lat = Number(data.geoJSON.geometry.coordinates[0]) === 0? Number(data.geoJSON.geometry.coordinates[0]) : Number(data.geoJSON.geometry.coordinates[0]).toFixed(2)
            const long = Number(data.geoJSON.geometry.coordinates[1]) === 0? Number(data.geoJSON.geometry.coordinates[1]) : Number(data.geoJSON.geometry.coordinates[1]).toFixed(2)
            const className =  "btnTargetLocker c-pointer";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              Lat: ${lat}, Lon: ${long}
            </span>`;
          }
        },
      ],
      targetEmplacementData: {},
      targetLockerId: this.$route.params.targetEntity,
      lockerList: [],
      emplacementList: [],
      displayType: [],
      lockerCardsPage: 0,
      display: {
        NewLockerModal: false,
        editLockerModal: false,
        linkLockerModal: false,
        deleteLockerModal: false,
        configurationView: false
      },
      linkAction: "link",
      currentIndex: 0,
      editingPermission: false,
      fullSize: false,
      fullHeight: false,
      isUpdatingMap: false,
    }
  },
  computed: {
    lockerCardsByPages() {
      let lockerCardsByPages = []
      let i = 0;
      while (i * 12 < this.lockerList.length) {
        lockerCardsByPages.push(this.lockerList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return lockerCardsByPages;
    },
    currentLocker() {
      return this.lockerList[this.currentIndex] || {};
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies", "handleHasRol"],
  methods: {
    nextLocker() {
      if (this.currentIndex < this.lockerList.length - 1) {
        this.currentIndex += 1;
      }
    },
    prevLocker() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.lockersMap) {
            this.lockersMap.invalidateSize();
          }
        }, 300);

      });
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "blueprint") this.displayLockersBlueprint();
        if (type === "map") {
          this.$nextTick(() => {
            this.displayLockersMap()
          });
        } else if (type === "table") {
          this.$nextTick(() => {
            this.displayLockersDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);
        this.displayType = [...this.displayType];
      }

      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.lockersMap) {
            this.lockersMap.invalidateSize();
          }
        }, 300);

      });
    },
    onSetCardPage(index) {
      this.lockerCardsPage = index;
      this.setButtonFunctions();
    },
    displayLockersBlueprint() {
      setTimeout(() => {
        $(".lockerBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.lockerList) {
            if (this.lockerList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.lockerList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(locker) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForLockers');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = locker.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = locker.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },
    displayLockersMap() {
      if (this.isUpdatingMap) return; // Si ya está en ejecución, salir
    this.isUpdatingMap = true;

    try {
      const mapContainer = document.getElementById("lockersMapContent");
      if (!mapContainer || mapContainer.offsetWidth === 0 || mapContainer.offsetHeight === 0) {
        return;
      }
      if (this.lockersMap) {
        this.lockersMap.eachLayer((layer) => this.lockersMap.removeLayer(layer));
        this.lockersMap.off();
        this.lockersMap.remove();
        this.lockersMap = undefined;
      }
      const lockersMapCreator = Leaflet.map("lockersMapContent");
      Leaflet.tileLayer(
        `https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${this.session.user.language}`,
        { maxZoom: 19 }
      ).addTo(lockersMapCreator);
      const siteMarkersGroup = new MarkerClusterGroup({ maxClusterRadius: 1 });
      lockersMapCreator.addLayer(siteMarkersGroup);
      let lockersMapListForGroup = [];
      siteMarkersGroup.addLayers(
        this.lockerList.map((m) => {
          const options = { title: m.label, clickable: true, draggable: false };
          let coordinates = [0, 0];
          if (
            m.parent === "" ||
            this.$route.params.currentModule === "lockers"
          ) {
            coordinates = [
              m.geoJSON.geometry.coordinates[0],
              m.geoJSON.geometry.coordinates[1],
            ];
          } else {
            for (let ii of this.emplacementList) {
              if (m.parent === ii.id) {
                coordinates = [
                  ii.geoJSON.geometry.coordinates[0],
                  ii.geoJSON.geometry.coordinates[1],
                ];
                break;
              }
            }
          }
          function getIconType(activeAlarms, openedIncidences) {
            if (activeAlarms !== '0' && openedIncidences !== '0') return 'location';
            if (activeAlarms !== '0' && openedIncidences === '0') return 'warning';
            if (activeAlarms === '0' && openedIncidences !== '0') return 'alert-circle';
            return 'location';
          }

          const iconType = getIconType(m.activeAlarms, m.openedIncidences);
          const color =
            iconType === 'warning' || iconType === 'alert-circle'
              ? 'red'
              : iconType === 'location' && m.activeAlarms === '0' && m.openedIncidences === '0'
                ? 'green'
                : 'red';
          const iconHTML = `
  <ion-icon name="${iconType}" data-id="${m.id}" 
    class="btnTargetLocker border-white tx-36 c-pointer" 
    style="vertical-align: middle; color: ${color};">
  </ion-icon>`;

          const customIcon = Leaflet.divIcon({
            html: iconHTML,
            className: "",
            iconSize: [52, 52],
          });

          const marker = Leaflet.marker(coordinates, { ...options, icon: customIcon });


          marker.on("mouseover", function () {
            marker.openPopup();
          });
          marker.on("mouseout", function () {
            marker.closePopup();
          });

          marker.on("click", (e) => {
            const targetId = e.target._icon.querySelector(".btnTargetLocker").getAttribute("data-id");
            
            this.$router.push({ params: { currentDisplay: "locker", targetEntity: targetId } });
          });


          marker.bindPopup(`
          <div>
            <strong>${m.label}</strong><br>
            Incidencias: ${m.openedIncidences}<br>
            Alarmas: ${m.activeAlarms}
          </div>
        `);

          lockersMapListForGroup.push(marker);
          return marker;
        })
      );


      siteMarkersGroup.options.iconCreateFunction = (cluster) => {
        const markers = cluster.getAllChildMarkers();
        const hasRed = markers.some((marker) =>
          marker.options.icon.options.html.includes('color: red')
        );
        const color = hasRed ? "red" : "green";
        return Leaflet.divIcon({
          html: `<div style="background-color: ${color}; width: 40px; height: 40px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white;">${cluster.getChildCount()}</div>`,
          className: "",
        });
      };


      this.$nextTick(() => {
        lockersMapCreator.invalidateSize();
        if (lockersMapListForGroup.length > 0) {
          const lockersMapGroup = Leaflet.featureGroup(lockersMapListForGroup);
          lockersMapCreator.fitBounds(lockersMapGroup.getBounds());

          if (lockersMapGroup.getLayers().length < 2) {
            this.zoomLocked = true;
            this.$nextTick(() => {
              const currentZoom = lockersMapCreator.getZoom();
              lockersMapCreator.setZoom(currentZoom - 3);
            });
          }
        } else {
          lockersMapCreator.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
        }
      });


      this.$nextTick(() => {
        lockersMapCreator.invalidateSize();
      });
      const attributionControl = document.querySelector(".leaflet-control-attribution");
      if (attributionControl) attributionControl.remove();
      this.lockersMap = lockersMapCreator;
    } catch (error) {
      console.error("Error displaying the map:", error);
    } finally {
      this.isUpdatingMap = false; 
    }
    },
    displayLockersDataTable() {
      if ($.fn.DataTable.isDataTable("#lockersDataTable")) {
        $("#lockersDataTable").DataTable().clear().destroy();
      }      
      const table = $("#lockersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.lockersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
      for (let i in this.lockerList) {
        table.rows.add([{
          lockerId: this.lockerList[i].id,
          lockerEmplacement: this.lockerList[i].parent,
          locker: this.lockerList[i],
          lockerLocation: this.lockerList[i].parent,
          lockerType: this.lockerList[i].type,
          lockerStatus: this.lockerList[i].status,
          lockerCommunicationDateTime: (this.lockerList[i].communicationDateTime != undefined && this.lockerList[i].communicationDateTime != "") ? new Date(this.lockerList[i].communicationDateTime) : "",
          lockerVersion: this.lockerList[i].version,
          lockerConsinga: this.lockerList[i].variables?.analog['consignaTermostato']?.value,
          lockerHisteresis: this.lockerList[i].variables?.analog['histeresisTermostato']?.value,
          lockerAlarms:  this.lockerList[i].activeAlarms==0? '-': this.lockerList[i].activeAlarms,
          lockerIncidences: this.lockerList[i].openedIncidences ==0? '-' : this.lockerList[i].openedIncidences
        }]);
      }
      table.draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    },
    async toggleNewLockerModal() {
      if (this.display.NewLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.NewLockerModal = !this.display.NewLockerModal;
    },

    async toggleLinkLockerModal() {
      if (this.display.linkLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.linkLockerModal = !this.display.linkLockerModal;
    },
    async refreshView() {
      await this.getEmplacementList();
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
        this.$emit('getAvailableCompanies', (this.displayLockersDataTable));
      }
      else if (this.targetEmplacementId != '') {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
        this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
      }
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocker);
        });
        $(".btnLinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkLocker);
        });
        $(".btnUnlinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkLocker);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    },
    async toggleConfigurationView() {
      if (this.display.configurationView) {
        await this.getEmplacementList();
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
         
          this.$nextTick(() => {
            this.$nextTick(() => {
              $("#lockersDataTable").DataTable(
                {
                  pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  dom: 'Bfrtip',
                  buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn ms-auto' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                  ],
                  columns: this.lockersColumns,
                  select: this.dataTableOptions.select,
                  language: this.dataTableOptions.language[this.session.user.language],
                  stateSave: this.dataTableOptions.stateSave,
                }
              );
              $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
              this.$emit('getAvailableCompanies', (this.displayLockersDataTable));
            });
            
          });
          
        }
        else if (this.targetEmplacementId != '') {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
        }
       
        if (this.lockerList[0] != undefined && this.targetLockerId === "")
          this.targetLockerId = this.lockerList[0].id;
         
        this.fullSize = false;     
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.lockersMap) {
              this.lockersMap.invalidateSize();
              this.displayLockersMap();
            }
          }, 300);
        });
      }
      this.display.configurationView = !this.display.configurationView;
    },
    // GET
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getLockersDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.lockerList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    //UPDATE
    updateTargetLockerId(newId) {
      this.targetLockerId = newId;
    },
    // TARGET 
    onTargetLocker(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      /* this.$router.replace({ params: { targetEntity: this.targetLockerId } }) */
      this.$router.push({ params: { currentDisplay: "locker", targetEntity: this.targetLockerId } });
    },
    // SET 
    onEditLocker(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id');
      this.$router.push({ params: { targetEntity: this.targetLockerId } })
      this.toggleEditLockerModal();
    },
    // LINK 
    onLinkLocker(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.push({ params: { targetEntity: this.targetLockerId } })
      this.linkAction = "link"
      this.toggleLinkLockerModal();
    },
    // UNLINK 
    onUnlinkLocker(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      this.linkAction = "unlink"
      this.toggleLinkLockerModal();
    },
    // DELETE
    async onDeleteLocker(event) {
      if (!this.display.deleteLockerModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetLockerId);
        this.targetLockerId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      }
      else {
        let response = await api.deleteLocker(this.targetLockerId);
        if (response["status-code"] === "200") {
          window.alert("El locker ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el locker.");
        }
      }
      await this.toggleDeleteLockerModal();
    }
  },
  async created() {
    const currentDisplay = this.$route.params.currentDisplay;
    const module = this.$route.params.currentModule;
    const moduleData = JSON.parse(localStorage.getItem(module));
    if (moduleData && moduleData[currentDisplay]) {
      const storedData = moduleData[currentDisplay];
      if (storedData.fullSize !== undefined) {
        this.fullSize = storedData.fullSize;
      }
      if (storedData.displayType !== undefined) {
        this.displayType = storedData.displayType;
        if (!this.displayType.includes('map')) {
          this.displayLockersMap();
        }
      }
    }
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
      await this.getLockersDataByCompany();
      this.$emit('getAvailableCompanies', (this.displayLockersDataTable));
      this.displayLockersMap();
    }
    else if (this.targetEmplacementId != '') {
      await this.getEmplacementData();
      await this.getLockersDataByEmplacement();
      this.$emit('getAvailableCompanies');
    }
    if (this.lockerList[0] != undefined && this.targetLockerId === "")
      this.targetLockerId = this.lockerList[0].id;
  },
  mounted() {
    
    this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
  }
}
</script>

<style>
.btnCopyId:hover,
.btnTargetLocker:hover,
.btnLinkLocker:hover,
.btnUnlinkLocker:hover,
.btnEditLocker:hover,
.btnDeleteLocker:hover {
  color: var(--main-dark) !important;
}
</style>