<template>
  <!-- SECTION Incidences-->
  <div id="incidencesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">

          <div class="position-sticky col-12 m-0 p-0 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-lg-10 col-md-10 col-12 m-0 pt-0 ps-3 pe-4 text-white text-start rounded-5 rounded-end">
                <div
                  class="col-xl-3 col-lg-4 col-md-6 col-12 m-0 mb-1 mt-1 pt-0 ps-0 text-white justify-content-start d-flex flex-nowrap">
                  <h6 class="tx-left text-white tx-14 text-center mt-1 mb-1 ps-1 pt-1 text-nowrap me-3">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Incidencias"][session.user.language] }}
                  </h6>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <div class="col text-end p-0 m-0 mb-0 pt-1 pe-3" style="width: 160px !important;">
                <button v-if="this.session.user.rol === '255'" id="btnDisplayNewMachineModal"
                  style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                  @click="toggleNewIncidenceModal">
                  <span>
                    <span class="button-text text-white">
                      <ion-icon name="add-circle-outline" class="pt-0 tx-18 hydrated" data-bs-toggle="tooltip"
                        data-bs-placement="bottom" title="Añadir"></ion-icon>
                      <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="incidences")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                    </span>
                  </span>
                </button>
                <button style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                  @click="this.$emit('toggleConfigurationView')">
                  <span>
                    <span class="button-text text-white">
                      <ion-icon name="return-down-back-outline" class="pt-0 me-0 tx-18 hydrated"></ion-icon>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- CONTENT table -->
          <div class="row col-lg-12 col-md-12 col-12 ps-4 p-3 pt-0 pb-0  mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja m-0 p-3 mt-3 mb-1 ms-2 rounded-5 shadow"
              style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="incidencesTable" class="table table-striped p-0 pb-3 bg-caja overflow-auto">
                <thead class="bg-lat tx-14 p-0 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in incidencesColums" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>

  <!-- MODAL New Incidence -->
  <NewIncidenceModal v-if="display.newIncidenceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewIncidenceModal="toggleNewIncidenceModal">
  </NewIncidenceModal>

  <!-- MODAL Edit Incidence -->
  <EditIncidenceModal v-if="display.editIncidenceModal" :session="session" :dictionary="dictionary"
    :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany" :incidenceId="targetIncidenceId"
    v-on:toggleEditIncidenceModal="toggleEditIncidenceModal">
  </EditIncidenceModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteIncidenceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Incidencia a eliminar'][session.user.language] + ': '" :alertTarget="targetIncidenceLabel"
    @acceptAlert="confirmDeleteIncidence" @dismissAlert="toggleDeleteIncidenceModal">
  </TextAlertModal>

</template>

<script>

import $ from 'jquery';
import NewIncidenceModal from '@/components/incidences/NewIncidenceModal';
import EditIncidenceModal from '@/components/incidences/EditIncidenceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "IncidencesConfigurationComponent",
  components: { NewIncidenceModal, EditIncidenceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    targetincidenceId: async function () {
      if (this.$route.params.currentDisplay === 'incidences') {
        await this.getIncidencesByCompany();
      }

    },
    currentCompany: async function () {
      this.historicSearchFilter.StartDate =
        await this.getincidenceList();
      if (this.$route.params.currentDisplay === 'incidences') {
        await this.getIncidencesByCompany();
      }
      this.$emit('getAvailableCompanies');
      const dates = this.displayDates();
      this.historicSearchFilter.StartDate = dates.sevenDaysAgo;
      this.historicSearchFilter.EndDate = dates.today;
    }
  },
  data() {
    return {
      incidencesColums: [
        {
          text: "Identificador", data: "incidenceId", width: "20%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetEmplacement c-pointer tx-12 tx-bold tx-clomos-midtone align-middle'>" + data + "</span>";
          }
        },
        {
          text: "Nombre", data: "incidenceLabel", width: "20%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnTargetEmplacement c-pointer'>" + data.label + "</span>"
          }
        },

        {
          text: "Editar", data: "incidenceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id=" + data + " class='btnEditIncidence btn tx-18 p-0 tx-16 text-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "incidenceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteIncidence btn tx-18 p-0 tx-16 text-danger'></ion-icon>"
          }
        }
      ],
      selectedIncidence: 'all',
      requestedSearch: {

      },
      targetincidenceData: {},
      targetIncidenceId: "",
      targetIncidenceLabel: "",
      incidenceList: [],
      display: {
        newIncidenceModal: false,
        editIncidenceModal: false,
        deleteIncidenceModal: false,
      },
      displayHistoricIncidence: false,
      historicSearchFilter: {},
      selectedCompany: "",
    }

  },

  emits: [ "getAvailableCompanies",'toggleConfigurationView'],
  methods: {
    // DISPLAY
    displayincidencesDataTable() {
      $("#incidencesTable").DataTable().clear();
      for (let i in this.incidenceList) {
        $("#incidencesTable").DataTable().rows.add([{
          incidenceId: this.incidenceList[i].id,
          incidenceLabel: this.incidenceList[i]
        }]);
      }
      $("#incidencesTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewIncidenceModal() {
      if (this.display.newIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences') {
          await this.getIncidencesByCompany();
          this.displayincidencesDataTable()
        }
      }
      this.display.newIncidenceModal = !this.display.newIncidenceModal;
      this.resetSelectedIncidence
    },
    async toggleEditIncidenceModal() {
      if (this.display.editIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences') {
          await this.getIncidencesByCompany();
          this.displayincidencesDataTable()
        }
      }
      this.display.editIncidenceModal = !this.display.editIncidenceModal;
    },
    async toggleDeleteIncidenceModal() {
      if (this.display.deleteIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences') {
          await this.getIncidencesByCompany();
          this.displayincidencesDataTable()
        }
        if (this.incidenceList[0] != undefined) {
          this.targetIncidenceId = this.incidenceList[0].id;
          this.targetIncidenceLabel = this.incidenceList[0].label;
        }
      }
      this.display.deleteIncidenceModal = !this.display.deleteIncidenceModal;
    },

    // BUTTONS 
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnEditIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onEditIncidence);
        });
        $(".btnDeleteIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteIncidence);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    // GET  
    async getIncidencesByCompany() {
      let request = {
        nifCif: this.currentCompany ? this.currentCompany.nifCif : this.session.user.nifCif,
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceList = api.parseIncidenceListFromResponse(response);
    },

    // SET 
    onEditIncidence(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.targetIncidenceLabel = this.incidenceList.find(incidence => incidence.id === this.targetIncidenceId)?.label || "";
      this.$router.replace({ params: { targetEntity: this.targetIncidenceId } })
      this.toggleEditIncidenceModal();
    },
    async onDeleteIncidence(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.targetIncidenceLabel = this.incidenceList.find(incidence => incidence.id === this.targetIncidenceId)?.label || "";
      await this.toggleDeleteIncidenceModal();
    },
    // DELETE
    async confirmDeleteIncidence() {
      try {
        let response = await api.deleteIncidence(this.targetIncidenceId);
        if (response["status-code"] === "200") {
          window.alert("La incidencia ha sido eliminado satisfactoriamente.");
        } else {
          throw new Error("Error al eliminar la incidencia.");
        }
      } catch (error) {
        window.alert(error.message);
      } finally {
        await this.toggleDeleteIncidenceModal();
      }
    }

  },
  async created() {
    await this.getIncidencesByCompany();
    this.displayincidencesDataTable();
  },
  mounted() {
    $("#incidencesTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.incidencesColums,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    )
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style></style>