<template>
  <!-- MODAL Edit Locker -->
  <div id="editLockerModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar locker'][session.user.language]+": "+editLockerData.label}}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditLockerModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Locker -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0">

<!-- FORM Locker -->
            <form class="p-0 pe-3 overflow-hidden">
              
              <div class="form-group border rounded mb-3 col-12 d-flex flex-wrap">

<!-- NOMBRE -->
                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Nombre'][session.user.language]}}
                  </label>
                  <input v-model="editLockerData.label" id="inputEditLockerName" type="text" 
                  class="form-control form-control-line" style="color:black;">
                </div>
                
<!-- PADRE -->
                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Emplazamiento'][session.user.language]}}
                  </label>
                  <select readonly disabled v-model="editLockerData.parent" 
                  id="selectNewLockerType"
                  class="form-control form-control-line" style="color:black;">
                    <option v-for="emplacement in emplacementList" :key="emplacement.id" :value="emplacement.id">{{emplacement.label}}</option>
                  </select>
                </div>

<!-- LATITUD -->
                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Latitud'][session.user.language]}}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="editLockerData.geoJSON.geometry.coordinates[0]" id="inputEditBundleId" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- LONGITUD -->
                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Longitud'][session.user.language]}}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="editLockerData.geoJSON.geometry.coordinates[1]" id="inputEditBundleId" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

                <div class="col-lg-6 col-12 m-0 p-0">
                </div>

                <div class="form-group col-lg-6 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
<!-- SUBMIT MACHINE DATA-->
                    <button type="button" @click="setEditLockerData"
                    id="btnSubmitLockerData" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar cambios'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>

              <div class="form-group border rounded mb-3 col-12 d-flex flex-wrap">

                <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editLockerData.variables.analog)[0]!=undefined">
                  {{"ANA"}}
                </label>

                <div v-for="variable in editLockerData.variables.analog" :key="variable.analogVariableId"
                class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='true')?'display:block':'display:none'">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{variable.label}}
                    </label>
                    <input v-model="editLockerData.variables.analog[variable.label].value" :id="'inputEditLocker'+variable.label" type="text" 
                    :data-id="editLockerData.variables.analog[variable.label].variableId"
                    :data-type="editLockerData.variables.analog[variable.label].type" class="inputEditLocker form-control form-control-line" style="color:black;">
                  </div>
                </div>
                <div class="col-12"></div>

                <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editLockerData.variables.digital)[0]!=undefined">
                  {{"DIG"}}
                </label>

                <div v-for="variable in editLockerData.variables.digital" :key="variable.digitalVariableId"
                class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='false')?'display:none':'display:block'">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{variable.label}}
                    </label>
                    <div :id="'inputEditLocker'+variable.label" 
                    :data-id="editLockerData.variables.digital[variable.label].variableId"
                    :data-type="editLockerData.variables.digital[variable.label].type"
                    :data-value="editLockerData.variables.digital[variable.label].value"
                    class="inputEditLocker form-control form-control-line border-0 tx-bold"
                    :class="editLockerData.variables.digital[variable.label].value"
                    @click="(editLockerData.variables.digital[variable.label].value==='true')?editLockerData.variables.digital[variable.label].value='false':editLockerData.variables.digital[variable.label].value='true'">
                      {{ dictionary[editLockerData.variables.digital[variable.label].value][session.user.language] }}
                    </div>
                  </div>
                </div>
                <div class="col-12"></div>

                <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editLockerData.variables.dynamic)[0]!=undefined&&Object.keys(editLockerData.variables.dynamic)[0]!='configuracion'">
                  {{"DIN"}}
                </label>

                <div v-for="variable in editLockerData.variables.dynamic" :key="variable.dynamicVariableId"
                class="col-lg-3 col-md-6 col-12" :style="((variable.isConfiguration==='false')||(variable.isConfiguration==='true' && variable.label==='configuracion'))?'display:none':'display:block'">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{variable.label}}
                    </label>
                    <input v-model="editLockerData.variables.dynamic[variable.label].value" 
                    :id="'inputEditLocker'+variable.label" type="text" 
                    :data-id="editLockerData.variables.dynamic[variable.label].variableId"
                    :data-type="editLockerData.variables.dynamic[variable.label].type" class="inputEditLocker form-control form-control-line" style="color:black;">
                  </div>
                </div>
                <div class="col-12"></div>

                <div class="col-lg-6 col-12 m-0 p-0">
                </div>

                <div class="form-group col-lg-6 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
<!-- SUBMIT SETTINGS-->
                    <button type="button" @click="setLockerVariables"
                    id="btnSubmitLockerVariables" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar variables'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>  

              <div class="form-group border rounded mb-3 col-12 d-flex flex-wrap">

                <div class="col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary['Configuración'][session.user.language]}}
                      <span class="ps-1 small">(JSON)</span>
                    </label>
                    <textarea v-model="lockerConfigurationForm.value" type="text" 
                    id="inputEditLockerConfiguration"
                    class="form-control form-control-line" style="color:black; text-align: start;"></textarea>
                  </div>
                </div>

                <div class="col-lg-6 col-12 m-0 p-0">
                </div>

                <div class="form-group col-lg-6 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
<!-- SUBMIT CONFIGURATION-->
                    <button type="button" @click="setLockerConfiguration"
                    id="btnSubmitLockerConfiguration" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar configuración'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

<!-- FORM My Profile (End) -->
              </div>  
            
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditLockerModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    lockerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editLockerData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: { 
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      lockerConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      emplacementList: []
    }
  },
  emits: ["toggleEditLockerModal"],
  methods: {
    // SET
    async setEditLockerData() {
      let response = await api.setLockerData(this.editLockerData);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditLockerData();
    },
    async setLockerVariables() {
      let variablesData = {
        lockerId : this.editLockerData.id
      };
      $(".inputEditLocker").get().forEach(input => {
        let variableLabel = input.id.replace("inputEditLocker","");
        let ic = false;
        if (this.editLockerData.variables.analog[variableLabel] != undefined
        && this.editLockerData.variables.analog[variableLabel].isConfiguration==="true")
          ic = true;
        if (this.editLockerData.variables.digital[variableLabel] != undefined
        && this.editLockerData.variables.digital[variableLabel].isConfiguration==="true")
          ic = true;
        if (this.editLockerData.variables.dynamic[variableLabel] != undefined
        && this.editLockerData.variables.dynamic[variableLabel].isConfiguration==="true")
          ic = true;
        if (ic) {
          variablesData[variableLabel] = {
          value: (input.getAttribute("data-type")==="0")
            ?(input.getAttribute("data-value")==="true")
              ?1
              :0
            :input.value,
          id: input.getAttribute("data-id"),
          type: input.getAttribute("data-type")}
        }
      });
      let response = await api.setLockerVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditLockerData();
    },
    async setLockerConfiguration() {
      let response = await api.setLockerConfiguration(this.lockerConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditLockerData();
    },
    // GET 
    async getEditLockerData(callback) {
      let response = await api.getLockerData(this.lockerId);
      console.log(response)
      this.editLockerData = api.parseMachineFromResponse(response);
      if (this.editLockerData.variables.dynamic['configuracion']!=undefined)
        this.lockerConfigurationForm = {
          id: this.editLockerData.id,
          variableId: this.editLockerData.variables.dynamic['configuracion'].variableId,
          value: this.editLockerData.variables.dynamic['configuracion'].value
        }
      else 
        this.lockerConfigurationForm = {
          id: this.editLockerData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.lockerData);
    },
    async getEmplacementList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      for (let i in response.plantas) [
        this.emplacementList[i] = {
          id: response.plantas[i].idDcl,
          label: response.plantas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getEditLockerData();
    await this.getEmplacementList();
  }
}
</script>

<style>

#editLockerModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>