<template>
  <!-- MODAL Link Device -->
  <div id="textAlertModal" class="col-12 justify-content-center" style="padding: 15vh 40%">
    <div class="col-12 m-0 p-0 section-container rounded-5 bg-caja shadow modal-lg">
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon :name="(linkAction === 'link') ? 'log-in-outline' : 'log-out-outline'"
              class="tx-18 me-2 align-bottom"></ion-icon>
            {{ ((linkAction === 'link')
              ? dictionary['Vincular'][session.user.language] : dictionary['Desvincular'][session.user.language]) + " " +
              this.targetDeviceData.label }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="this.$emit('dismissAlert')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>
      <div class="form-group col-12 p-0 pe-3">
        <div id="linkDeviceScroll" class="col-12 p-3" style=" overflow-x: hidden; max-height: 60vh;">
          <div class="row col-12 border border rounded m-0 p-1 pb-0">
            <div class="col-lg-12 col-12">
              <div class="col-12 m-0 p-2 d-flex flex-column tx-left">
                <span class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Dispositivo'][session.user.language] + ": " }}
                </span>
                <span class="col-12 m-0 p-0 tx-12">
                  {{ targetDeviceId }}
                </span>
              </div>

              <div v-if="linkAction === 'link'" class="col-12 m-0 p-2 d-flex flex-column tx-left">
                <span class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Tipo'][session.user.language] + ": " }}
                </span>
                <select class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                  v-model="parentType" @change="onChangeParentType">
                  <option v-if="this.$route.params.currentModule === 'devices'
                    || this.$route.params.currentModule === 'electricalMonitoring'" value="device">
                    {{ dictionary['Dispositivo'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule === 'fabric'
                    || this.$route.params.currentModule === 'smartLockers'" value="bundle">
                    {{ dictionary['Conjunto'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule === 'fabric'
                    || this.$route.params.currentModule === 'smartLockers'
                    || this.$route.params.currentModule === 'electricalMonitoring'" value="machine">
                    {{ dictionary['Máquina'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule === 'devices'
                    || this.$route.params.currentModule === 'fabric'
                    || this.$route.params.currentModule === 'storage'
                    || this.$route.params.currentModule === 'smartLockers'
                    || this.$route.params.currentModule === 'electricalMonitoring'" value="emplacement">
                    {{ dictionary['Emplazamiento'][session.user.language] }}
                  </option>
                </select>

                <span v-if="parentType === 'device'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Dispositivo'][session.user.language] + ": " }}
                </span>
                <input v-if="parentType === 'device'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                <select v-if="parentType === 'device'" class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex"
                  style="color:black; line-height:" v-model="targetEntityId" @change="onTargetEntity">
                  <option v-for="device in filteredDevices" :key="device" :value="device.id"
                    :selected="device.id === targetEntityId">
                    {{ device.label }}
                  </option>
                </select>

                <span v-if="parentType === 'bundle'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Conjuntos'][session.user.language] + ": " }}
                </span>
                <input v-if="parentType === 'bundle'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                <select v-if="parentType === 'bundle'" class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex"
                  style="color:black; line-height:" v-model="targetEntityId" @change="onTargetEntity">
                  <option v-for="bundle in filteredBundles" :key="bundle" :selected="bundle.id === targetEntityId"
                    :value="bundle.id">
                    {{ bundle.label }}
                  </option>
                </select>

                <span v-if="parentType === 'machine'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Máquinas'][session.user.language] + ": " }}
                </span>
                <input v-if="parentType === 'machine'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                <select v-if="parentType === 'machine'" id="selectTargetDevice"
                  class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                  v-model="targetEntityId" @change="onTargetEntity">
                  <option v-for="machine in filteredMachines" :key="machine.id" :value="machine.id"
                    :selected="machine.id === targetEntityId">
                    {{ machine.label }}
                  </option>
                </select>

                <span v-if="parentType === 'emplacement'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Emplazamiento'][session.user.language] + ": " }}
                </span>
                <input v-if="parentType === 'emplacement'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                <select v-if="parentType === 'emplacement'" class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex"
                  style="color:black; line-height:" v-model="targetEntityId" @change="onTargetEntity">
                  <option v-for="emplacement in filteredEmplacements" :key="emplacement"
                    :selected="emplacement.id === targetEntityId" :value="emplacement.id">
                    {{ emplacement.label }}
                  </option>
                </select>

              </div>
              <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
                <!-- SUBMIT -->
                <button type="button" id="btnSubmitTargetDevice" class="button-ok gradient-tablas w-100"
                  @click="linkDeviceToEntity">
                  {{ dictionary['Aceptar'][session.user.language] }}
                </button>
              </div>

              <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
                <!-- DISMISS -->
                <button type="button" id="btnCancelTargetDevice" class="button-ok gradient-tablas w-100"
                  @click="this.$emit('dismissAlert')">
                  {{ dictionary['Cancelar'][session.user.language] }}
                </button>
              </div>
            </div>
            <div class="row m-0 p-2">
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

// Clomos
import api from '@/services/api';

export default {
  name: "TargetDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    targetDeviceId: {
      type: String,
      required: true,
    },
    linkAction: {
      type: String,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
  },
  watch: {
    filteredMachines(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredDevices(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredBundles(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredEmplacements(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    }
  },
  data() {
    return {
      targetDeviceData: {},
      parentType: "emplacement",
      targetEntityId: "",
      targetEntity: {},
      availableDevices: [],
      availableBundles: [],
      availableMachines: [],
      availableEmplacements: [],
      newGeoJSON: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0, 0]
        }
      },
      deviceMap: undefined,
      deviceMarker: undefined,
      searchQuery: ""
    }
  },
  computed: {
    
    filteredMachines() {
      if (!Array.isArray(this.availableMachines) || this.availableMachines.length === 0) {
        return [];
      }
      return this.availableMachines.filter(machine => {
        const label = machine.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredDevices() {
      if (!Array.isArray(this.availableDevices) || this.availableDevices.length === 0) {
        return [];
      }
      return this.availableDevices.filter(device => {
        const label = device.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredBundles() {
      if (!Array.isArray(this.availableBundles) || this.availableBundles.length === 0) {
        return [];
      }
      return this.availableBundles.filter(bundle => {
        const label = bundle.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });


    },
    filteredEmplacements() {
      if (!Array.isArray(this.availableEmplacements) || this.availableEmplacements.length === 0) {
        return [];
      }
      return this.availableEmplacements.filter(emplacement => {
        const label = emplacement.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });


    },

  },
  emits: ["dismissAlert"],
  methods: {
    // DISPLAY
   
    
    onChangeParentType() {
      this.targetEntity = {};
      this.searchQuery = ''
      if (this.parentType === 'device') {
        this.getDeviceList();
      }
      if (this.parentType === 'bundle') {
        this.getBundleList();
      }
      if (this.parentType === 'machine') {
        this.getMachineList();
      }
      if (this.parentType === 'emplacement') {
        this.getEmplacementList();
      }
    },
    // GET
    async getTargetDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.targetDeviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetDeviceData);
    },
    async getDeviceList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      let allAvailableDevices = api.parseDevicesFromResponse(response);
      this.availableDevices = [];
      for (let i in allAvailableDevices) {
        if (allAvailableDevices[i].id != this.targetDeviceId) this.availableDevices.push(allAvailableDevices[i])
      }
      if (this.availableDevices[0] != undefined) {
        this.targetEntityId = this.availableDevices[0].id;
        this.targetEntity = this.availableDevices[0];
      }
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getBundleList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.availableBundles = api.parseBundlesFromResponse(response);
      if (this.availableBundles[0] != undefined) {
        this.targetEntityId = this.availableBundles[0].id;
        this.targetEntity = this.availableBundles[0];
      }
      if (callback && typeof callback === "function") callback(this.availableBundles);
    },
    async getMachineList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.availableMachines = api.parseMachinesFromResponse(response);
      if (this.availableMachines[0] != undefined) {
        this.targetEntityId = this.availableMachines[0].id;
        this.targetEntity = this.availableMachines[0];
      } if (callback && typeof callback === "function") callback(this.availableMachines);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.availableEmplacements = api.parseEmplacementsFromResponse(response);
      if (this.availableEmplacements[0] != undefined) {
        this.targetEntityId = this.availableEmplacements[0].id;
        this.targetEntity = this.availableEmplacements[0];
      } if (callback && typeof callback === "function") callback(this.availableEmplacements);
    },
    // TARGET
    onTargetEntity(event) {
      this.targetEntityId = event.target.value;
      if (this.parentType === 'device') for (let i in this.availableDevices) {
        if (this.availableDevices[i].id === this.targetEntityId)
          this.targetEntity = this.availableDevices[i];
        
      }
      else if (this.parentType === 'bundle') for (let i in this.availableBundles) {
        if (this.availableBundles[i].id === this.targetEntityId)
          this.targetEntity = this.availableBundles[i];        
      }
      else if (this.parentType === 'machine') for (let i in this.availableMachines) {
        if (this.availableMachines[i].id === this.targetEntityId)
          this.targetEntity = this.availableMachines[i];        
      }
      else if (this.parentType === 'emplacement') for (let i in this.availableEmplacements) {
        if (this.availableEmplacements[i].id === this.targetEntityId)
          this.targetEntity = this.availableEmplacements[i];       
      }
    },   
    async linkDeviceToEntity(callback) {
      
      let response = {};
      let request = {
        deviceId: this.targetDeviceId,
        parentId: this.targetEntityId,
        geoJSON: this.newGeoJSON
      }
      if (this.parentType === 'device') {
        response = await api.setDeviceParentAsDevice(request);
      }
      else if (this.parentType === 'bundle') {
        response = await api.setDeviceParentAsBundle(request);
      }
      else if (this.parentType === 'machine') {
        response = await api.setDeviceParentAsMachine(request);
      }
      else if (this.parentType === 'emplacement') {
        response = await api.setDeviceParentAsEmplacement(request);
      }
      if (response["status-code"] === "200") this.$emit('dismissAlert');
      if (callback && typeof callback === "function") callback(this.availableEmplacements);
    }
  },
  async created() {
    await this.getTargetDeviceData();
    if (this.linkAction === "link") {
      await this.getEmplacementList();
      if (this.availableEmplacements[0] != undefined)
        this.targetEntityId = this.availableEmplacements[0].id;     
    }
    else {
      this.targetEntityId = "";     
    }
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>