<template>
  <!-- SECTION Devices-->
  <div id="devicesSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3" style="overflow: hidden;"
          :style="(!display.showEverything) ? '' : 'height: 400px !important; overflow-y: scroll;'">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select v-if="this.$route.params.currentDisplay === 'devices'" :value="currentCompany.nifCif"
                    class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>
                <div class="row col-xl-3 col-lg-3 col-md-5 col-12 justify-content-start m-0 p-0 pt-1">
                  <button @click="onChangeDisplayType('map')" :title="dictionary['Mapa'][session.user.language]"
                    :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="map-outline"
                          :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="pt-0 tx-18 hydrated md" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                    :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="list-outline"
                          :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class=" tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="documents-outline"
                          :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="hardware-chip-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Dispositivos"][session.user.language] }}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewMachineModal"
                    :title="dictionary['Añadir dispositivo'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleNewDeviceModal">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class=" pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir dispositivo'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button v-if="editingPermission" id="btnDisplayEditEraseModal" style="width: 30px; max-height: 30px;"
                    :title="dictionary['Editar dispositivo'][session.user.language]"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="toggleConfigurationView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="pt-0 me-0 tx-18 hydrated"
                          :title="dictionary['Editar dispositivo'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" style="width: 30px; max-height: 30px;"
                    :title="dictionary['Actualizar'][session.user.language]"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="refresh-outline" class="pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <!--Fin Segunda sección-->


            </div>
          </div>
          <!-- MAP Devices -->

          <div v-show="!displayType.includes('map')" :class="fullSize ? 'col-lg-12' : 'col-lg-4'"
            class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto "
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1" @click="togglechangeDisplaySize()"
                style="border-top: solid 5px #005793;">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div id="devicesMapContent" class="col-12 bg-caja rounded-5 shadow"  
              :style="displayType.includes('cards') && displayType.includes('table') ? 'min-height: 80vh;' : 'min-height: 530px;'"
              style="z-index: 0; height: auto; border-top: solid 5px #005793; border-bottom: solid 2px #008db4; margin-top: -20px;">
            </div>
          </div>
          <div v-show="!displayType.includes('cards')" id="devicesCardsContent"
            :class="fullSize ? 'col-lg-12' : 'col-lg-8'" class="row  col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div class="row col-12 bg-caja rounded-5 shadow" :class="fullSize ? 'ms-1 me-3' : 'ms-0'"
              style="min-height: 480px; z-index: 0; min-height: 530px; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:-20px;">
              <div v-for="device in deviceCardsByPages[deviceCardsPage]" :key="device"
                class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6
                  class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span id="cardLabel" :data-id="device.id" class="m-auto btnTargetDevice c-pointer">{{
                    (device.label.length > 15) ? (device.label.substring(0, 15) + '...') :
                      device.label }}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom m-0 p-2 h-auto" style="border-color: #ccc !important; ">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 180px;">
                    <span class="tx-13 tx-bold">
                      {{
                        device.type.length > 0
                          ? dictionary['Tipo'][session.user.language]
                          : ""
                      }}
                      {{
                        device.type.length > 0 && device.category.length > 0
                          ? " / "
                          : ""
                      }}
                      {{
                        device.category.length > 0
                          ? dictionary['Categoría'][session.user.language]
                          : ""
                      }}
                    </span>
                    <span class="tx-11 tx-start">{{
                      device.type.length > 0
                        ? device.type
                        : ""
                    }} {{
                        device.type.length > 0 && device.category.length > 0
                          ? "/"
                          : ""
                      }}{{
                        device.category.length > 0
                          ? device.category
                          : ""
                      }}</span>
                    <span class="tx-13 tx-bold">{{ dictionary['Fecha de comunicación'][session.user.language] + ": "
                      }}</span>
                    <span class="tx-11 tx-start">{{ (device.communicationDateTime != undefined &&
                      device.communicationDateTime != "") ? this.$dayjs(new
                        Date(device.communicationDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat)
                      : "-" }}</span>
                    <span class="tx-13 tx-bold">{{ dictionary['Versión'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ device.version ? device.version : '-' }}</span>
                    <span class="tx-14 tx-bold">{{ dictionary['Posición'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ "Lat: " + (Number(device.geoJSON.geometry.coordinates[0]) === 0 ?
                      Number(device.geoJSON.geometry.coordinates[0]) :
                      Number(device.geoJSON.geometry.coordinates[0]).toFixed(2)) + ", Lon: "+(Number(device.geoJSON.geometry.coordinates[1]) === 0?
                    Number(device.geoJSON.geometry.coordinates[1]) :
                    Number(device.geoJSON.geometry.coordinates[1]).toFixed(2)) }}</span>
                    <span class="d-flex justify-content-between align-items-center pt-1 pb-1">
                      <ion-icon v-if="device.openedIncidences != '0'" name="alert-circle-outline" :data-id="device.id"
                        :title="dictionary['Incidencias'][session.user.language] + ': ' + device.openedIncidences"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                      <ion-icon v-if="device.activeAlarms != '0'" name="warning-outline" :data-id="device.id"
                        :title="dictionary['Alarmas'][session.user.language] + ': ' + device.activeAlarms"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 pt-0 p-2">
                <div v-for="page, index in deviceCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-13"
                  :class="(deviceCardsPage === index) ? 'border-secondary' : ''" style="height:1.5rem; width:1.5rem;"
                  @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- TABLE Devices -->
          <div v-show="!displayType.includes('table')"
            class="row col-lg-12 col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto" style="max-width: 99.5%;">
            <div id="tableContent" class="col-12 bg-caja m-0 p-3 mt-3 mb-1 ms-2 rounded-5 shadow table-responsive"
              :style="fullHeight ? 'min-height: 80vh;' : 'min-height: 480px;'"
              style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="devicesDataTable" class="table table-striped p-0 pb-3 bg-caja overflow-auto">
                <thead class="bg-lat tx-14 p-0 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in devicesColumns" :key="column.data" :width="column.width"
                      class="fw-medium text-nowrap p-2">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <!-- CARDS Devices -->
          <div v-show="!displayType.includes('cards') && deviceList[0] == undefined" class="m-3 p-3 border rounded">
            <span class="tx-13">
              {{ dictionary['No hay dispositivos disponibles'][session.user.language] }}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- MODAL New Device -->
  <NewDeviceModal v-if="display.newDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

  <div v-if="this.display.configurationView">
    <DevicesConfigurationComponent :session="session" :dictionary="dictionary" :countries="countries"
      :timeZones="timeZones" :visibility="visibility" :currentCompany="currentCompany"
      :availableCompanies="availableCompanies" :targetEmplacementId="''" :targetMachineId="''"
      :typesByCompany="typesByCompany" :dataTableOptions="dataTableOptions"
      v-on:toggleConfigurationView="toggleConfigurationView">
    </DevicesConfigurationComponent>
  </div>
</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import DevicesConfigurationComponent from '@/components/devices/DevicesConfigurationComponent';
// Clomos
import api from '@/services/api';

export default {
  name: "DevicesComponent",
  components: { NewDeviceModal, DevicesConfigurationComponent },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    targetMachineId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    }
  },
  watch: {
    fullSize: async function (newValue) {
      const currentDisplay = this.$route.params.currentDisplay;
      const module = this.$route.params.currentModule;
      let moduleData = JSON.parse(localStorage.getItem(module)) || {};
      moduleData[currentDisplay] = moduleData[currentDisplay] || {};
      moduleData[currentDisplay].fullSize = newValue;
      localStorage.setItem(module, JSON.stringify(moduleData));
    },

    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
        await this.getDevicesDataByCompany();
      }
      else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
        await this.getDevicesDataByMachine();
      }
      this.displayDevicesMap();
      this.displayDevicesDataTable();
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetEmplacementId: async function () {
      if (this.targetEmplacementId != "") {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      this.displayDevicesMap();
      this.displayDevicesDataTable();
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetMachineId: async function () {
      if (this.targetMachineId != "") {
        await this.getDevicesDataByMachine();
      }
      this.displayDevicesMap();
      this.displayDevicesDataTable();
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    displayType: {
      handler: async function (newValue) {
        
        if (newValue.includes("cards") && newValue.includes("map") && !newValue.includes("table")) {
          this.fullHeight = true;
        } else {
          this.fullHeight = false;
        }
        if (!newValue.includes("cards") && newValue.includes("map")) this.fullSize = true;
        const currentDisplay = this.$route.params.currentDisplay;
        const module = this.$route.params.currentModule;
        let moduleData = JSON.parse(localStorage.getItem(module)) || {};
        moduleData[currentDisplay] = moduleData[currentDisplay] || {};
        moduleData[currentDisplay].displayType = newValue;
        localStorage.setItem(module, JSON.stringify(moduleData));
      },
      deep: true
    },

    fullHeight(newValue) {
      const dataTable = $("#devicesDataTable").DataTable();
      dataTable.page.len(newValue ? 20 : 10).draw();
    }
  },
  data() {
    return {
      devicesColumns: [

        {
          text: "Nombre", data: "deviceLabel", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnTargetDevice c-pointer'>" + data.label + "</span>"
          }
        },
        { text: "Tipo", data: "deviceType", width: "10%", className: "tx-left align-middle" },
        { text: "Categoría", data: "deviceCategory", width: "5%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "deviceCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Alarmas", data: "deviceLabel", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            const activeAlarms = data.activeAlarms === '0' ? '-' : data.activeAlarms
            const className = activeAlarms !== '-' ? "btnTargetDevice c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${activeAlarms}
            </span>`;
          }
        },
        {
          text: "Incidencias", data: "deviceLabel", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            let openedIncidences = data.openedIncidences === '0' ? '-' : data.openedIncidences
            const className = openedIncidences !== '-' ? "btnTargetDevice c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${openedIncidences}
            </span>`;
          }
        },
        { text: "Versión", data: "deviceVersion", width: "5%", className: "tx-left align-middle" },
        {
          text: "Posición", data: "deviceLabel", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            const lat = Number(data.geoJSON.geometry.coordinates[0]) === 0 ? Number(data.geoJSON.geometry.coordinates[0]) : Number(data.geoJSON.geometry.coordinates[0]).toFixed(2)
            const long = Number(data.geoJSON.geometry.coordinates[1]) === 0 ? Number(data.geoJSON.geometry.coordinates[1]) : Number(data.geoJSON.geometry.coordinates[1]).toFixed(2)
            const className = "btnTargetLocker c-pointer";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              Lat: ${lat}, Lon: ${long}
            </span>`;
          }
        },
        {
          text: "",
          data: "distributionGroup",
          visible: false,
        }
      ],
      targetEmplacementData: {},
      targetMachineData: {},
      targetDeviceId: this.$route.params.targetEntity,
      deviceList: [],
      emplacementList: [],
      devicesMap: undefined,
      displayType: [],
      deviceCardsPage: 0,
      display: {
        showEverything: false,
        configurationView: false,
        newDeviceModal: false
      },
      fullSize: false,
      linkAction: "link",
      editingPermission: false,
      treeConfig: { nodeWidth: 250, nodeHeight: 120, levelHeight: 120 },
      fullHeight: false,
      isUpdatingMap: false,
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: { lng: 0, lat: 0 },
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.deviceList[1] != undefined) {
        for (let i in this.deviceList) if (this.deviceList[i].geoJSON.geometry != undefined && this.deviceList[i].geoJSON.geometry.type === "Point") {
          if (this.deviceList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.deviceList[i].geoJSON.geometry.coordinates[0])
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.deviceList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.deviceList[i].geoJSON.geometry.coordinates[1])
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[1] <= l || l === undefined) {
            l = Number(this.deviceList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r + l) / 2;
        viewpoint.center.lat = (t + b) / 2;
      }
      else if (this.deviceList[0] != undefined) { // Single emplacement
        viewpoint.center = { lng: parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[0]), lat: parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[1]) };
      }
      return viewpoint;
    },
    deviceCardsByPages() {
      let deviceCardsByPages = []
      let i = 0;
      while (i * 12 < this.deviceList.length) {
        deviceCardsByPages.push(this.deviceList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return deviceCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies", 'toggleConfigurationView', "handleHasRol"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.devicesMap) {
            this.devicesMap.invalidateSize();
          }
        }, 300);
      });
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "map") {
          this.$nextTick(() => {
            this.displayDevicesMap();
          });
        } else if (type === "table") {

          this.$nextTick(() => {
            this.displayDevicesDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);
        this.displayType = [...this.displayType];

      }

      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.devicesMap) {
            this.devicesMap.invalidateSize();
          }
        }, 300);

      });
    },
    onSetCardPage(index) {
      this.deviceCardsPage = index;
      this.setButtonFunctions();
    },
    displayDevicesBlueprint() {
      setTimeout(() => {
        $(".deviceBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.deviceList) {
            if (this.deviceList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.deviceList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(device) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForDevices');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = device.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = device.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },
    displayDevicesMap() {
      if (this.isUpdatingMap) return; 
    this.isUpdatingMap = true;

    try {
      const mapContainer = document.getElementById("devicesMapContent");
      if (!mapContainer || mapContainer.offsetWidth === 0 || mapContainer.offsetHeight === 0) {
        return;
      }

      if (this.devicesMap) {
        this.devicesMap.eachLayer((layer) => {
          this.devicesMap.removeLayer(layer);
        });
        this.devicesMap.off();
        this.devicesMap.remove();
        this.devicesMap = undefined;
      }
      var devicesMap = Leaflet.map("devicesMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(devicesMap);


      var siteMarkersGroup = new MarkerClusterGroup();
      devicesMap.addLayer(siteMarkersGroup);

      let devicesMapListForGroup = [];
      siteMarkersGroup.addLayers(
        this.deviceList.map((m) => {
          const options = { title: m.label, clickable: true, draggable: false };
          let coordinates = [0, 0];


          coordinates = [
            m.geoJSON.geometry.coordinates[0],
            m.geoJSON.geometry.coordinates[1],
          ];


          let emplacement =
            m.emplacement && m.emplacement !== ""
              ? m.emplacement
              : "";


          function getIconType(activeAlarms, openedIncidences) {
            if (activeAlarms !== '0' && openedIncidences !== '0') return 'location';
            if (activeAlarms !== '0' && openedIncidences === '0') return 'warning';
            if (activeAlarms === '0' && openedIncidences !== '0') return 'alert-circle';
            return 'location';
          }

          const iconType = getIconType(m.activeAlarms, m.openedIncidences);
          const color =
            iconType === 'warning' || iconType === 'alert-circle'
              ? 'red'
              : iconType === 'location' && m.activeAlarms === '0' && m.openedIncidences === '0'
                ? 'green'
                : 'red';

          const iconHTML = `
        <ion-icon name="${iconType}" data-id="${m.id}" 
          class="btnTargetDevice border-white tx-36 c-pointer " 
          style="vertical-align: middle; color: ${color};">
        </ion-icon>`;

          const customIcon = Leaflet.divIcon({
            html:
              iconHTML,
            className: "",
            iconSize: [52, 52],
          });

          const marker = Leaflet.marker(coordinates, { ...options, icon: customIcon });
          marker.on("mouseover", function () {
            marker.openPopup();
          });
          marker.on("mouseout", function () {
            marker.closePopup();
          });
          marker.on("click", (e) => {
            this.targetDeviceId = e.target._icon.querySelector(".btnTargetDevice").getAttribute("data-id");
            if (this.$route.params.currentDisplay === 'devices') {
              this.$router.push({ params: { currentDisplay: "device", targetEntity: this.targetDeviceId } });
            }
          });
          // Contenido del popup
          marker.bindPopup(
            `<div class="d-flex flex-column" style="min-width: 9rem;">
          <span data-id="${m.id}" class="btnTargetDevice c-pointer tx-bold tx-grey m-0 p-0">${m.label}</span>
          <div class="m-0 p-0 d-flex flex-row justify-content-between">
            <text class="w-100">${emplacement && emplacement !== 'null' ? emplacement : ''}</text>
          </div><div class="m-0 p-0 row justify-content-start">           
            <text class="w-100">Tipo: ${m.type}</text>           
          </div>
          <div class="m-0 p-0 row justify-content-start">           
            <text class="w-100">Fecha comunicación: ${(m.communicationDateTime != undefined &&
              m.communicationDateTime != "") ? this.$dayjs(new
                Date(m.communicationDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat)
              : ""}</text>           
          </div>
          <div class="m-0 p-0 row justify-content-start">           
            <text class="w-100">Version: ${m.version}</text>           
          </div><div class="m-0 p-0 row justify-content-start">           
            <text class="w-100">Número de incidencias: ${m.openedIncidences}</text>
            <text class="w-100"> Número de alarmas: ${m.activeAlarms}</text>
          </div>
        </div>`
          );

          devicesMapListForGroup.push(marker);
          return marker;
        })
      );
      siteMarkersGroup.options.iconCreateFunction = function (cluster) {
        const markers = cluster.getAllChildMarkers();
        let hasRedMarker = false;
        markers.forEach((marker) => {
          if (marker.options.icon.options.html.includes('color: red')) {
            hasRedMarker = true;
          }
        });


        const clusterColor = hasRedMarker ? 'red' : 'green';
        return Leaflet.divIcon({
          html: `
      <div style="background-color: ${clusterColor}; border-radius: 50%; 
                  width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; 
                  color: white; font-size: 16px; border: 2px  ; transform: translate(-15px, 0);"">
        ${markers.length}
      </div>
    `,
          className: '',

        });
      };

      this.$nextTick(() => {
        if (devicesMapListForGroup[0] != undefined) {
          const devicesMapGroup = Leaflet.featureGroup(devicesMapListForGroup);
          devicesMap.fitBounds(devicesMapGroup.getBounds());
          if (devicesMapGroup.getLayers().length < 2) {
            this.$nextTick(() => {
              const currentZoom = devicesMap.getZoom();
              devicesMap.setZoom(currentZoom - 3);
            });
          }
        } else {
          devicesMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
        }
      })
      this.$nextTick(() => {
        devicesMap.invalidateSize();
      });
      const attributionControl = document.querySelector(".leaflet-control-attribution");
      if (attributionControl) attributionControl.remove();
      this.devicesMap = devicesMap;
    } catch (error) {
      console.error("Error displaying the map:", error);
    } finally {
      this.isUpdatingMap = false; 
    }
    },
    displayDevicesDataTable() {
      
      if ($.fn.DataTable.isDataTable("#devicesDataTable")) {
        $("#devicesDataTable").DataTable().clear().destroy();
      }
      const table = $("#devicesDataTable").DataTable({
        pagingType: 'full_numbers',
        pageLength: this.fullHeight ? 20 : 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[5, 'asc']]
      });
      for (let i in this.deviceList) {
        let parentLabel = "";
        if (this.targetMachineId != "") {
          parentLabel = this.targetMachineData.label;
        }
        else {
          for (let ii in this.emplacementList) {
            if (this.emplacementList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.emplacementList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.machineList) {
            if (this.machineList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.machineList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.bundleList) {
            if (this.bundleList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.bundleList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.deviceList) {
            if (this.deviceList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.deviceList[ii].label;
              break;
            }
          }
        }
        table.rows.add([{
          deviceId: this.deviceList[i].id,
          deviceLabel: this.deviceList[i],
          deviceCommunicationDateTime: (this.deviceList[i].communicationDateTime != undefined && this.deviceList[i].communicationDateTime != "") ? new Date(this.deviceList[i].communicationDateTime) : "",
          deviceType: this.deviceList[i].type,
          deviceParent: { parentId: this.deviceList[i].parent, parentLabel: parentLabel, deviceId: this.deviceList[i].id },
          deviceVersion: this.deviceList[i].version,
          distributionGroup: this.deviceList[i].distributionGroup,
          deviceCategory: this.deviceList[i].category,
        }]);
      }
      table.draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
      
    },
    async toggleConfigurationView() {
      if (this.display.configurationView) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
        if (this.deviceList[0] != undefined && this.targetDeviceId === "")
          this.targetDeviceId = this.deviceList[0].id;
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.devicesMap) {
              this.devicesMap.invalidateSize();
              this.displayDevicesMap();
            }
          }, 300);

          this.$nextTick(() => {

            $("#devicesDataTable").DataTable(
              {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                  { "extend": 'colvis', "className": 'btn' },
                  { "extend": 'copy', "className": 'btn' },
                  { "extend": 'csv', "className": 'btn' },
                  { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.devicesColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                stateSave: this.dataTableOptions.stateSave,
              }
            );
            $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
            this.displayDevicesDataTable();
          });
        });
      }
      this.display.configurationView = !this.display.configurationView;

    },
    async refreshView() {
      if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
        await this.getDevicesDataByCompany();
      }
      else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
        await this.getDevicesDataByMachine();
      }
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.devicesMap) {
            this.devicesMap.invalidateSize();
            this.displayDevicesMap();
          }
        }, 300);
        if (this.deviceList[0] != undefined && this.targetDeviceId === "")
          this.targetDeviceId = this.deviceList[0].id;
        this.$nextTick(() => {
          this.displayDevicesDataTable();
        });
      });
    },
    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
        if (this.deviceList[0] != undefined && this.targetDeviceId === "")
          this.targetDeviceId = this.deviceList[0].id;
        this.displayDevicesDataTable();
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["emplacements"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getMachineList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["machines"] === undefined
        && this.session.authorisation.availableModules[this.$route.params.currentModule]["commandCenters"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getBundleList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["bundles"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getDevicesDataByCompany(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      this.deviceList.sort((a, b) => {
        const groupA = Number(a.distributionGroup);
        const groupB = Number(b.distributionGroup);
        return groupA - groupB;
      });
      for (let i = 0; i < this.deviceList.length; i++) {
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.deviceList[i].dclTypeId);
        this.deviceList[i].category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByMachine(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      this.deviceList = [];
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      let childResponse;
      let child = this.targetMachineData;
      let parent;
      // LVL 0
      if (this.targetMachineData.children != "") {
        parent = { ...child };
        let n = 0
        for (let childId in child.children) {
          if (child.children != "" && child.children[childId].claseDcl != "3") {
            n += 1;
            continue;
          }
          childResponse = await api.getDeviceData(childId);
          child = api.parseDeviceFromResponse(childResponse);
          if (child.dclClass === "3") {
            this.deviceList.push(child);
            // LVL 1
            if (child.children != "") {
              parent = { ...child };
              let nn = 0;
              for (let childId in child.children) {
                if (child.children != "" && child.children[childId].claseDcl != "3") {
                  nn += 1;
                  continue;
                }
                childResponse = await api.getDeviceData(childId);
                child = api.parseDeviceFromResponse(childResponse);
                if (child.dclClass === "3") {
                  this.deviceList.push(child);
                  // LVL 2
                  if (child.children != "") {
                    parent = { ...child };
                    let nnn = 0;
                    for (let childId in child.children) {
                      if (child.children != "" && child.children[childId].claseDcl != "3") {
                        nnn += 1;
                        continue;
                      }
                      childResponse = await api.getDeviceData(childId);
                      child = api.parseDeviceFromResponse(childResponse);
                      if (child.dclClass === "3") {
                        this.deviceList.push(child);
                        // LVL 3
                        if (child.children != "") {
                          parent = { ...child };
                          let nnnn = 0;
                          for (let childId in child.children) {
                            if (child.children != "" && child.children[childId].claseDcl != "3") {
                              nnnn += 1;
                              continue;
                            }
                            childResponse = await api.getDeviceData(childId);
                            child = api.parseDeviceFromResponse(childResponse);
                            if (child.dclClass === "3") {
                              this.deviceList.push(child);
                              // LVL 4
                              if (child.children != "") {
                                parent = { ...child };
                                let nnnnn = 0;
                                for (let childId in child.children) {
                                  if (child.children != "" && child.children[childId].claseDcl != "3") {
                                    nnnnn += 1;
                                    continue;
                                  }
                                  childResponse = await api.getDeviceData(childId);
                                  child = api.parseDeviceFromResponse(childResponse);
                                  if (child.dclClass === "3") {
                                    this.deviceList.push(child);
                                  }
                                  nnnnn += 1;
                                  if (Object.keys(child) === nnnnn) child = { ...parent };
                                }
                              }
                            }
                            nnnn += 1;
                            if (Object.keys(child) === nnnn) child = { ...parent };
                          }
                        }
                      }
                      nnn += 1;
                      if (Object.keys(child) === nnn) child = { ...parent };
                    }
                  }
                }
                nn += 1;
                if (Object.keys(child) === nn) child = { ...parent };
              }
            }
          }
          n += 1;
          if (Object.keys(child) === n) child = { ...parent };
        }
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByEmplacement(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByEmplacement(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      this.deviceList.sort((a, b) => {
        const groupA = Number(a.distributionGroup);
        const groupB = Number(b.distributionGroup);
        return groupA - groupB;
      });
      for (let i = 0; i < this.deviceList.length; i++) {
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.deviceList[i].dclTypeId);
        this.deviceList[i].category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')

      if (this.$route.params.currentDisplay === 'devices') {
        this.$router.push({ params: { currentDisplay: "device", targetEntity: this.targetDeviceId } });
      }

    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    }
  },
  async created() {
    await this.getEmplacementList();
    await this.getMachineList();
    const currentDisplay = this.$route.params.currentDisplay;
    const module = this.$route.params.currentModule;
    const moduleData = JSON.parse(localStorage.getItem(module));
    if (moduleData && moduleData[currentDisplay]) {
      const storedData = moduleData[currentDisplay];
      if (storedData.fullSize !== undefined) {
        this.fullSize = storedData.fullSize;
      }
      if (storedData.displayType !== undefined) {
        this.displayType = storedData.displayType;
        if (!this.displayType.includes('map')) {
          this.displayDevicesMap();
        }
      }
    }

    if (currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
      await this.getDevicesDataByCompany();
    }
    else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
      await this.getEmplacementData();
      await this.getDevicesDataByEmplacement();
    }
    else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
      await this.getDevicesDataByMachine();
    }
    this.$emit('getAvailableCompanies');
    if (this.deviceList[0] != undefined && this.targetDeviceId === "")
      this.targetDeviceId = this.deviceList[0].id;

    this.displayDevicesMap()
    if (!this.displayType.includes('table')) {
    this.displayDevicesDataTable()
    }
  },
  mounted() {
    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: this.fullHeight ? 20 : 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[5, 'asc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetDevice:hover,
.btnLinkDevice:hover,
.btnUnlinkDevice:hover,
.btnEditDevice:hover,
.btnDeleteDevice:hover {
  color: var(--main-dark) !important;
}

#cardLabel:hover {
  color: var(--main-white) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

#organizationChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.device-node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.device-node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}
</style>