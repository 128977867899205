<template>
<!-- MODAL Text Alert -->
  <div id="textAlertModal"
  class="col-12 justify-content-center pe-6" 
    style="padding: 15vh 25%">
    <div class="col-12 m-0 p-0 bg-caja rounded-5">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <!--<ion-icon name="trash-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar dispositivo'][session.user.language]+": "+editDeviceData.label}}-->
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('dismissAlert')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="form-group col-12 p-3">
        <div class="col-12 m-0 p-0">
          
          <div class="col-12 border border rounded overflow-hidden m-0 p-1 pb-0">
            <div class="bg-menu text-white p-1 m-0 mb-3 rounded"> 
             {{  alertHeader }}
            </div>

            <span v-show="alertTarget!= ''" class="col-12">
              {{ alertTarget }}
            </span>

            <span v-for="alertText in alertTexts" :key="alertText" class="col-12">
              {{ alertText }}
            </span>

            <div v-if="alertInput != undefined && (alertInput === 'date' || alertInput === 'datetime-local')" class="col-12 d-flex flex-column m-0 p-0">
              <span class="col-12 tx-bold ">
                {{dictionary['Fecha'][session.user.language]+": "}}
              </span>
              <input id="alertInput" :type="alertInput" :value="alertValue" @change="onChange(event)"
              class="form-control form-control-line ps-2 pe-2" style="color:#000!important">
            </div>

            <div class="row p-2">

              <div class="col-lg-6 col-md-6 col-12 p-2 ps-1 pe-1">
<!-- SUBMIT -->
                <button type="button" id="btnSubmitCreateNewEmplacement" class="button-ok gradient-tablas w-100"
                @click="onSubmit">
                {{dictionary['Aceptar'][session.user.language]}}
                </button>
              </div>

              <div class="col-lg-6 col-md-6 col-12 p-2 ps-1 pe-1">
<!-- DISMISS -->
                <button type="button" id="btnSubmitCreateNewEmplacement" class="button-ok gradient-tablas w-100"
                @click="onDismiss">
                {{dictionary['Cancelar'][session.user.language]}}
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
export default {
  name: "TextAlertModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    alertHeader: {
      type: String,
      required: true,
    },
    alertTarget: {
      type: String,
      required: true,
    },
    alertTexts: {
      type: Array,
      required: true,
    },
    alertInput: {
      type: String,
      required: true,
    },
    alertValue: {
      type: String,
      required: true,
    }
  },
  emits: ["acceptAlert", "dismissAlert", "updateAlertValue"],
  methods: {
    onSubmit() {
      if (this.alertInput != undefined) {
        if (this.alertValue === "") {
          if ($("#alertInput").get()[0] != undefined) $("#alertInput").get()[0].classList.add("missing");
          setTimeout(() => {
            if ($("#alertInput").get()[0] != undefined) $("#alertInput").get()[0].classList.remove("missing")
          }, 3000);
          return;
        }
      }
      this.$emit('acceptAlert');
    },
    onDismiss() {
      this.$emit('dismissAlert');
    },
    onChange(event) {
      this.$emit('updateAlertValue', event.target.value);
    },
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>