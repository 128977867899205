<template>
    <!-- SECTION Status-->
    <div id="statusSection" class="row col-12 m-0 p-0">
        <div class="row col-12 p-2 m-0 section-container justify-content-center">
            <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
                <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3" style="overflow: hidden;">
                    <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start ">
                        <div class="row p-0 m-0">
                            <!--Primera sección-->
                            <div id="head-section" class="row col-xl-6 col-lg-7 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                                    <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 pb-2 d-flex flex-row">
                                        <ion-icon name="pulse" class="tx-18 me-2 align-bottom"></ion-icon>
                                        <span>{{ deviceData.label }}</span>
                                        <span style="display: none;" v-if="deviceData.label != undefined"> : {{
                                            deviceData.label }} </span>
                                        <span v-else> {{ ": " + dictionary['No hay dispositivos disponibles'][session.user.language] }}
                                        </span>
                                    </h6>
                                </div>
                            </div>
                            <!-- Fin Primera sección-->

                            <div class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                                <button id="btnDisplayEditEraseModal"
                                    :title="dictionary['Incidencias'][session.user.language]"
                                    style="width: 30px; max-height: 30px;"
                                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="scrollToIncidences">
                                    <span>
                                        <span class="button-text text-white">
                                            <ion-icon name="alert-circle-outline"
                                                class="pt-0 me-0 tx-18 hydrated"
                                                :title="dictionary['Incidencias'][session.user.language]"></ion-icon>
                                        </span>
                                    </span>
                                </button>
                                <button id="btnDisplayEditEraseModal"
                                    :title="dictionary['Alarmas'][session.user.language]"
                                    style="width: 30px; max-height: 30px;"
                                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="scrollToActiveAlarms">
                                    <span>
                                        <span class="button-text text-white">
                                            <ion-icon name="warning-outline" class="pt-0 me-0 tx-18 hydrated"
                                                :title="dictionary['Alarmas'][session.user.language]"></ion-icon>
                                            <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                                        </span>
                                    </span>
                                </button>
                                <button v-if="editingPermission" id="btnDisplayEditEraseModal"
                                    style="width: 30px; max-height: 30px;"
                                    :title="dictionary['Editar dispositivo'][session.user.language]"
                                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="toggleEditDeviceModal">
                                    <span>
                                        <span class="button-text text-white">
                                            <ion-icon name="create-outline" class="pt-0 me-0 tx-18 hydrated"
                                                :title="dictionary['Editar dispositivo'][session.user.language]"></ion-icon>
                                        </span>
                                    </span>
                                </button>
                                <button id="btnDisplayEditEraseModal"
                                    :title="dictionary['Volver'][session.user.language]"
                                    style="width: 30px; max-height: 30px;"
                                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="this.$router.go(-1)">
                                    <span>
                                        <span class="button-text text-white">
                                            <ion-icon name="return-down-back-outline"
                                                class="pt-0 me-0 tx-18 hydrated"
                                                :title="dictionary['Volver'][session.user.language]"></ion-icon>
                                            <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row col-lg-12 col-xl-12 col-md-12 col-12 ps-4 pt-2" style="min-height: 200px;">
                        <div class="col-12 bg-caja rounded-5 shadow"
                            style=" border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
                            <div class="row justify-content-around">
                                <div v-if="deviceData.children && deviceData.children.length > 0"
                                    class="border rounded d-flex flex-row justify-content-center ">
                                    <div v-for="child in deviceData.children" :key="child.idDcl" class="p-3"
                                        style="min-width: 200px;">
                                        <div class="d-flex flex-row justify-content-center rounded-top-3 p-1 tx-bold"
                                            :style="{ backgroundColor: (child.claseDcl === '2') ? '#3498db' : (child.claseDcl === '3') ? '#2ecc71' : (child.claseDcl === '4') ? '#e67e22' : (child.claseDcl === '7') ? '#e74c3c' : '#f39c12' }">
                                            <ion-icon
                                                :name="(child.claseDcl === '2') ? 'cog-outline' : (child.claseDcl === '3') ? 'hardware-chip-outline' : (child.claseDcl === '4') ? 'grid-outline' : (child.claseDcl === '7') ? 'layers-outline' : ''"
                                                class="tx-18 m-auto ms-0 me-2 align-bottom text-white"></ion-icon>
                                            <span class="text-white tx-14">
                                                {{ child.etiqueta }}
                                            </span>
                                        </div>

                                        <div
                                            class="d-flex flex-row justify-content-center space-around rounded-bottom-3 border-1 p-1 tx-dark bg-white">
                                            <span class="m-auto">
                                                {{ child.nombreTipo }}
                                            </span>
                                            <ion-icon name='eye-outline' :data-id="child.idDcl"
                                                class='btnGoToEntity btn ms-auto tx-18 tx-primary'
                                                @click="onGoToEntity(child)"></ion-icon>
                                        </div>
                                    </div>
                                </div>
                                <!-- CARD Variables -->
                                <div class="col-lg-5 col-12 m-0 p-0 pt-3 pe-4" style="min-height: 100%">
                                    <div class="row mb-1 justify-content-around">
                                        <div class="col-12 row p-3 pt-0 ">
                                            <div class="border-bottom row p-0 m-0">
                                                <!-- Primera fila -->
                                                <div class="col-11 row mb-2">
                                                    <!-- Tipo -->
                                                    <div class="col-3 d-flex flex-column">
                                                        <span class="tx-13 tx-bold mt-2">
                                                            {{ dictionary['Tipo'][session.user.language] + ": " }}
                                                        </span>
                                                        <span class="tx-11 tx-start">
                                                            {{ deviceData.type ? deviceData.type : "" }}
                                                        </span>
                                                    </div>
                                                    <div class="col-3 d-flex flex-column">
                                                        <span class="tx-13 tx-bold mt-2">
                                                            {{ dictionary['Categoría'][session.user.language] + ": " }}
                                                        </span>
                                                        <span class="tx-11 tx-start">
                                                            {{ deviceData.category ? deviceData.category : "" }}
                                                        </span>
                                                    </div>
                                                    <div class="col-3 d-flex flex-column">
                                                        <span class="tx-13 tx-bold mt-2">
                                                            {{ dictionary['Versión'][session.user.language] + ": " }}
                                                        </span>
                                                        <span class="tx-11 tx-start">
                                                            {{ deviceData.version }}
                                                        </span>
                                                    </div>
                                                    <div class="col-3 d-flex flex-column">
                                                        <span class="tx-13 tx-bold mt-2">
                                                            {{  dictionary['Comunicación'][session.user.language]  + ": " }}
                                                        </span>
                                                        <span class="tx-11 tx-start">
                                                            {{ deviceData.communicationDateTime !== undefined &&
                                                                deviceData.communicationDateTime !== "" ?
                                                                this.$dayjs(new Date(deviceData.communicationDateTime))
                                                                    .utc("z").local().tz(this.session.user.dateTimeZone)
                                                                    .format(this.session.user.dateTimeFormat+ ' ' + 'HH:mm:ss') : "-" }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-1 ">
                                                    <ion-icon name='information-circle-outline' class='btnGoToEntity btn 
                                                             tx-28 mt-2 tx-primary'
                                                        @click="toggleViewDeviceModal"></ion-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="variableOptions in statusGaugeChartOptions"
                                            class=" col-md-6 col-lg-4 p-2 col-12 p-2" :key="variableOptions">
                                            <div class="bg-white border border-info rounded-3 pt-2"
                                                style="min-height: 80px;">
                                                <p class="tx-12 mb-0 text-start w-100 text-center"><strong>{{
                                                    variableOptions.series[0].data[0].name }} </strong></p>
                                                <span
                                                    v-if="variableOptions.series[0].data[0].name === 'Posicion' || variableOptions.series[0].data[0].type === 'digital'">
                                                    {{
                                                        (variableOptions.series[0].data[0].value === 'true' &&
                                                            variableOptions.valueDateTime != "") ?
                                                            dictionary['true'][session.user.language] :
                                                            (variableOptions.series[0].data[0].value === 'false' &&
                                                                variableOptions.valueDateTime != "") ?
                                                                dictionary['false'][session.user.language] :
                                                                variableOptions.valueDateTime != "" ?
                                                                    variableOptions.series[0].data[0].value : "--"

                                                    }}
                                                </span>
                                                <span v-else> {{
                                                    (variableOptions.valueDateTime != "" ?
                                                        variableOptions.series[0].data[0].value :
                                                        "--") +
                                                    variableOptions.series[0].detail.formatter.replace("{value}",
                                                        "")
                                                }}
                                                </span>
                                                <div class="d-flex d-row justify-content-around"
                                                    style="min-height: 20px;">
                                                    <span class="tx-11" style="color: #c5c6c7;">
                                                        {{ (variableOptions.series[0].data[0].name === 'Posicion' ||
                                                            variableOptions.series[0].data[0].type === 'digital') ? "" :
                                                            !(variableOptions.series[0].min === 0 &&
                                                                variableOptions.series[0].max === 0)
                                                                ? "min:" + variableOptions.series[0].min
                                                                : "" }}
                                                    </span>
                                                    <span class="tx-11" style="color: #c5c6c7;">
                                                        {{ (variableOptions.series[0].data[0].name === 'Posicion' ||
                                                            variableOptions.series[0].data[0].type === 'digital') ? "" :
                                                            !(variableOptions.series[0].min === 0 &&
                                                                variableOptions.series[0].max === 0)
                                                                ? "max:" + variableOptions.series[0].max
                                                                : "" }}
                                                    </span>
                                                </div>

                                                <span class="tx-11" style="color: #c5c6c7;">
                                                    {{

                                                        (variableOptions.valueDateTime === "" ? "-" : this.$dayjs(new
                                                            Date(variableOptions.valueDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat
                                                                +
                                                                " HH:mm"))
                                                    }}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- CARD Last Day -->
                                <div class="row col-12 col-lg-6 border-start mt-4">
                                    <div class="d-flex d-row justify-content-around align-items-center">
                                        <div class="col-lg-5 col-12">
                                            <select v-model="targetVariableId" id="selectTargetDevice"
                                                data-live-search="true" class="col-12 m-0 ps-1 tx-13 text-nowrap"
                                                style="padding-bottom: 3px;" @change=changeChartDisplay()>
                                                <option v-for="variable in historyVariables"
                                                    :key="variable['variableId']" :value="variable['variableId']"
                                                    :selected="variable['variableId'] === targetVariableId"
                                                    class="text-dark">
                                                    {{ variable['label'][0].toUpperCase() +
                                                        variable['label'].substring(1) }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 col-12 m-0 p-0">
                                            <div class="d-flex m-0 p-0 align-items-center">
                                                <!-- Input group -->
                                                <div class="d-flex align-items-center ">
                                                    <input v-model="startDateTimeInput" id="inputStartDate"
                                                        class=" form-control rounded text-nowrap text-dark me-2"
                                                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                                                        style="max-width: 140px; max-height: 34px; font-size:12px;">
                                                    <input v-model="endDateTimeInput" id="inputEndDate"
                                                        class=" form-control rounded text-nowrap text-dark"
                                                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                                                        style="max-width: 140px; max-height: 34px; font-size:12px;">
                                                </div>

                                                <!-- Icon group -->
                                                <div class="d-flex align-items-start">
                                                    <ion-icon name="refresh-outline"
                                                        class="tx-18 pe-0 pt-1 btn btnSizeChart "
                                                        @click="changeDisplayInfo()">
                                                    </ion-icon>
                                                    <ion-icon name="trash-outline"
                                                        class="tx-18 pe-0 pt-1 btn btnSizeChart"
                                                        @click="chartHistory = {}">
                                                    </ion-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bg-white pt-3 pb-2 m-0 ">
                                        <VueEcharts :option="chart1Options" id="Chart1Canvas"
                                            class="col-12 w-100 ps-0 pe-0" style="height: 16.25rem;">
                                        </VueEcharts>
                                    </div>
                                    <div v-if="this.deviceData?.variables?.dynamic?.Posicion"
                                        class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 mb-3 h-auto ">
                                        <div class="p-0 text-end text-dark rounded"
                                            style="z-index: 11;  position: relative;">
                                            <span class="me-1 bg-white border-2 border-primary p-1"
                                                @click="togglechangeDisplaySize()">
                                                <ion-icon v-if="!fullSize" name="expand-outline"
                                                    class="tx-16 mb-1 align-bottom"></ion-icon>
                                                <ion-icon v-if="fullSize" name="contract-outline"
                                                    class="tx-16 mb-1 align-bottom"></ion-icon>
                                            </span>
                                        </div>
                                        <div id="positionMapContent" class="col-12 bg-caja rounded-5 shadow"
                                            :style="!fullSize ? 'min-height: 14.6rem;' : 'min-height: 30.6rem;'"
                                            style="z-index: 0;  height: auto; margin-top: -20px;">
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-12 row m-0 ps-2 p-3 pt-0 pb-0 mt-2 h-auto" id="incidences">
                        <div class=" col-col-12 bg-caja rounded-5 shadow"
                            style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
                            <h6 class="mt-3">Tabla de Alarmas</h6>
                            <div class="row justify-content-around" id="activeAlarms">
                                <div id="tableContent"
                                    class=" mt-2 mb-2 col-lg-4 col-12  table-responsive m-0 p-3  mb-1   overflow-hidden">
                                    <table id="ActiveAlarmsDataTable"
                                        class="col-12 m-0 p-0 dataTables_wrapper no-footer" style="width: 100%;">
                                        <thead class="gradient-tablas tx-14 text-white text-center"
                                            style="position: -webkit-sticky; position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                                            <tr>
                                                <th v-for="column in activeAlarmColumns" :key="column.data"
                                                    :width="column.width" class="fw-medium text-nowrap p-1">
                                                    {{ dictionary[column.text][session.user.language] }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="m-0 mt-2 mb-2 p-0 col-lg-6 col-12 ">
                                    <VueEcharts :option="activeAlarmLineChartOptions" id="Chart2Canvas"
                                        class="col-12 ps-0 pe-0" style="height: 300px;">
                                    </VueEcharts>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!--Incidencias-->
                    <div class="col-md-12 col-12 row m-0 ps-2 p-3 pt-0 pb-0 mt-2 h-auto" id="incidences">
                        <div class=" col-col-12 bg-caja rounded-5 shadow"
                            style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
                            <h6 class="mt-3">Tabla de Incidencias</h6>
                            <!-- <img src="@/assets/img/incidencias.png" class="img-fluid mt-4"> -->
                            <div class="col-lg-12 col-md-12 p-2">
                                <!-- Buttons above the table -->
                                <div class="text-center">
                                    <div class="row justify-content-center p-3 pt-0 pb-2">
                                        <button @click="toggleFilter('0')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('0') ? 'btn-primary-active btn-custom-primary' : 'btn-custom-primary']">
                                            {{ dictionary["Pendiente de revisión"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('1')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('1') ? 'btn-secondary-active btn-custom-secondary' : 'btn-custom-secondary']">
                                            {{ dictionary["En investigación"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('2')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('2') ? 'btn-success-active btn-custom-success' : 'btn-custom-success']">
                                            {{ dictionary["En proceso de resolucion"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('3')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('3') ? 'btn-danger-active btn-custom-danger' : 'btn-custom-danger']">
                                            {{ dictionary["Solucionada"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('4')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('4') ? 'btn-warning-active btn-custom-warning' : 'btn-custom-warning']">
                                            {{ dictionary["Cerrada"][session.user.language] }}
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive p-2">
                                    <table v-show="incidenceList.length > 0" id="IncidencesDataTable"
                                        class="table table-striped p-0 pb-3 bg-caja w-100">
                                        <thead class=" tx-14 text-white text-center overflow-hidden gradient-tablas">
                                            <tr>
                                                <th v-for="column in incidenceColumns" :key="column.data"
                                                    :width="column.width" class="fw-medium text-nowrap p-2">
                                                    {{ dictionary[column.text][session.user.language] }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    
        <EditDeviceComponent v-if="display.viewDeviceModal" :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones" :typesByCompany="typesByCompany"
          :currentCompany="currentCompany"  v-on:toggleViewDeviceModal="toggleViewDeviceModal" >
        </EditDeviceComponent>
      

    <!--MODAL Edit Incidence-->
    <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary"
        :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
        :availableCompanies="availableCompanies" :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
        v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
    </EditHistoricModal>
    <!--MODAL Add Comments-->
    <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary"
        :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
        :availableCompanies="availableCompanies" :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
        v-on:toggleEditCommentsModal="toggleEditCommentsModal">
    </EditCommentsModal>

</template>

<script>
import $ from 'jquery';
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal';
import EditDeviceComponent from '@/components/devices/EditDeviceComponent';
import Leaflet from 'leaflet';
//import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// Clomos
import api from '@/services/api';

export default {
    name: "DeviceComponent",
    components: { VueEcharts, EditHistoricModal, EditCommentsModal, EditDeviceComponent},
    props: {
        session: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        },
        countries: {
            type: Object,
            required: true,
        },
        timeZones: {
            type: Object,
            required: true,
        },
        currentCompany: {
            type: Object,
            required: true,
        },
        availableCompanies: {
            type: Object,
            required: true,
        },
        visibility: {
            type: Object,
            required: true,
        },
        dataTableOptions: {
            type: Object,
            required: true,
        },
        typesByCompany: {
            type: Object,
            required: true,
        }
    },
    watch: {

        currentCompany: async function () {
            await this.getDeviceData();
            this.variableHistory = {};
            await this.getDeviceVariableHistory();
        },
        historyVariables: {
            immediate: true,
            async handler() {
                if (!this.targetVariableId && Object.entries(this.historyVariables)[0] != undefined) {
                    this.targetVariableId = Object.entries(this.historyVariables)[0][0];

                }
            }
        }

    },
    data() {
        return {
            incidenceColumns: [
                {
                    text: "", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
                    render: (data) => {
                        return "<ion-icon name='chevron-down-circle-outline' data-id=" + data + " class='btnDetailIncidence btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
                    }
                },
                {
                    text: "Incidencia", data: "IncidenceLabel", width: "10%", className: " align-middle",
                    render: (data) => {
                        return data;
                    }
                },
                {
                    text: "Estado",
                    data: "IncidenceHistoricStatus",
                    width: "10%",
                    className: "tx-left align-middle",
                    render: (data) => {
                        let text;
                        let color;

                        switch (data) {
                            case "0":
                                text = this.dictionary["Pendiente de revisión"][this.session.user.language];
                                color = "#3498db";
                                break;
                            case "1":
                                text = this.dictionary["En investigación"][this.session.user.language];
                                color = "#2ecc71";
                                break;
                            case "2":
                                text = this.dictionary["En proceso de resolucion"][this.session.user.language];
                                color = "#e67e22";
                                break;
                            case "3":
                                text = this.dictionary["Solucionada"][this.session.user.language];
                                color = "#e74c3c";
                                break;
                            case "4":
                                text = this.dictionary["Cerrada"][this.session.user.language];
                                color = "#f39c12";
                                break;
                            default:
                                text = data.status;
                                color = "black";
                        }

                        return `<span style="color: ${color};">${text}</span>`;
                    }
                },

                {
                    text: "Nivel de gravedad", data: "IncidenceLevel", width: "7%", className: "tx-left align-middle",
                    render: (data) => {
                        switch (data) {
                            case "0":
                                return "<div value='0' class='row'>" +
                                    "<span style='opacity: 0;'>" + 0 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: greenyellow; '></div>" +
                                    "</div>";
                            case "1":
                                return "<div value='1' class='row'>" +
                                    "<span style='opacity: 0;'>" + 1 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                                    "</div>";
                            case "2":
                                return "<div value='2' class='row'>" +
                                    "<span style='opacity: 0;'>" + 2 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "</div>";
                            case "3":
                                return "<div value='3' class='row'>" +
                                    "<span style='opacity: 0;'>" + 3 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "</div>";
                            case "4":
                                return "<div value='4' class='row'>" +
                                    "<span style='opacity: 0;'>" + 4 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "</div>";
                            default:
                                return data.status;
                        }


                    }
                }, {
                    text: "Fecha apertura", data: "openingDate", width: "10%", className: "tx-left align-middle",
                    render: (data) => {

                        return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
                    }
                },


                {
                    text: "Actualizar", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
                    render: (data) => {
                        return ("<ion-icon name='add-circle-outline' data-id=" + data + " class='btnModifyIncidence btn tx-24 text-black m-0 p-0'></ion-icon>"

                        )
                    }
                },

            ],
            activeAlarmColumns: [
                {
                    text: "", data: "activeAlarmId", width: "5%", className: "align-middle",
                    render: (data) => {
                        return "<input type='radio' name='select-alarm' value='" + data + "' class='radio-select-alarm btnDetailAlarm p-0'>";
                    }
                },
                {
                    text: "Alarma", data: "activeAlarmName", width: "70%", className: "tx-left align-middle p-0",
                    render: (data) => {
                        return data;
                    }
                },
                {
                    text: "Fecha Activacion", data: "activationDate", width: "50%", className: "tx-left align-middle p-0",
                    render: (data) => {

                        return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
                    }
                },


            ],
            // LAST DAY CHART
            lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
            // GAUGE CHARTS
            gaugeChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["gaugeChartOptions"],
            deviceData: {
                id: "",
                nifCif: "",
                dataConsumed: "",
                label: "",
                dclId: "",
                dclTypeId: "",
                dclClass: "",
                type: "",
                distributionGroup: "",
                parent: "",
                isBase: "",
                isPublic: 0,
                token: "",
                imgSrc: "",
                emplacement: "",
                fechaUltimaComunicacion: "",
                tieneAlarmaActivada: "",
                version: "",
                children: [],
                geoJSON: {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [0, 0]
                    }
                },
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {}
                }

            },
            variableHistory: {},
            startDateTimeInput: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
            endDateTimeInput: api.parseDateTimeToString(new Date()),
            startDateTime: {
            },
            endDateTime: {
            },
            chartHistory: {
            },
            checkboxTargetAxisLeft: true,
            checkboxTargetAxisRight: false,

            targetDeviceId: this.$route.params.targetEntity,
            targetVariableId: '',
            // COLORS
            colors: ["#9e9e9e",
                "#4e79a7",
                "#f28d2b",
                "#e05759",
                "#76b7b2",
                "#59a14f",
                "#af7aa1",
                "#b1de89",
                "#595055",
                "#edc948",
                "#1c8356",
                "#ff9da7",
                "#8d9fca",
                "#2f451e"],
            selectedStatuses: [],
            sortedIncidenceList: [],
            incidenceList: [],
            incidenceTypes: [],
            targetIncidenceId: '',
            display: {
                editHistoricModal: false,
                editCommentsModal: false,
                editDeviceModal: false,
                viewDeviceModal: false
            },
            positionMap: undefined,
            alarmsList: {},
            activeAlarmsList: {},
            preActive: {},
            postActive: {},
            targetActiveAlarm: "",
            activeAlarmLineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
            positionHistory: [],
            fullSize: false,
            editingPermission: false
        }
    },
    computed: {
        targetIncidence() {
            for (let i in this.sortedIncidenceList) {
                if (this.sortedIncidenceList[i].id === this.targetIncidenceId)
                    return this.sortedIncidenceList[i];
            }
            return {};
        },
        fractionalLength() {
            return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
        },
        // DIGITAL GAUGE CHARTS
        statusGaugeChartOptions() {
            let statusGaugeChartOptions = {};
            let rawDeviceData = JSON.parse(JSON.stringify(this.deviceData));
            if (rawDeviceData.variables === undefined) return statusGaugeChartOptions;
            for (let i in rawDeviceData.variables.analog) {
                if (rawDeviceData.variables.analog[i].isConfiguration === 'false') {
                    statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                    statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.analog[i].unit;
                    if (rawDeviceData.variables.analog[i].unit === "%") {
                        statusGaugeChartOptions[i].series[0].max = 100;
                        statusGaugeChartOptions[i].series[0].min = 0;
                    }
                    else {
                        statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.analog[i].max);
                        statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.analog[i].min);
                    }
                    statusGaugeChartOptions[i].series[0].data[0] = {
                        name: rawDeviceData.variables.analog[i].label.charAt(0).toUpperCase() + rawDeviceData.variables.analog[i].label.slice(1),
                        value: Number(rawDeviceData.variables.analog[i].value).toFixed(this.fractionalLength)
                    };
                    statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.analog[i].valueDateTime;
                }
            }
            for (let i in rawDeviceData.variables.digital) {
                if (rawDeviceData.variables.digital[i].isConfiguration === 'false') {
                    statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                    statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.digital[i].unit;


                    statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.digital[i].max);
                    statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.digital[i].min);
                    statusGaugeChartOptions[i].series[0].data[0] = {
                        name: rawDeviceData.variables.digital[i].label.charAt(0).toUpperCase() + rawDeviceData.variables.digital[i].label.slice(1),
                        value: rawDeviceData.variables.digital[i].value,
                        type: 'digital'
                    };
                    statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.digital[i].valueDateTime;
                }
            }
            if (rawDeviceData.variables.dynamic?.Posicion) {
                const value = api.parseStringToGeoJSON(rawDeviceData.variables.dynamic.Posicion.value)
                const [lat, lon] = value.geometry.coordinates;
                const formattedCoordinates = `${lat.toFixed(2)}/${lon.toFixed(2)}`;
                statusGaugeChartOptions["Posicion"] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                statusGaugeChartOptions["Posicion"].series[0].data[0] = {
                    name: rawDeviceData.variables.dynamic.Posicion.label.charAt(0).toUpperCase() + rawDeviceData.variables.dynamic.Posicion.label.slice(1),
                    value: formattedCoordinates,

                };
                statusGaugeChartOptions["Posicion"].valueDateTime = rawDeviceData.variables.dynamic.Posicion.valueDateTime;
            }
            return statusGaugeChartOptions;
        },
        // LAST DAY CHART
        lastDayLineChartOptions() {
            let lastDayLineChartOptions;
            let now = new Date().getTime();
            let yesterday = new Date().setDate(new Date().getDate() - 1);

            lastDayLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
            if (this.targetDeviceId === "") return lastDayLineChartOptions;
            lastDayLineChartOptions.axisPointer = {
                label: {
                    formatter: (function (dataTarget) {
                        let d = new Date(dataTarget.value);
                        return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
                    })
                },
            }
            lastDayLineChartOptions.xAxis = {
                min: yesterday,
                max: now,
                axisLabel: {
                    formatter: (function (value) {
                        let d = new Date(value);
                        return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
                    }),
                    textStyle: {
                        fontSize: 10
                    }
                }
            }
            lastDayLineChartOptions.yAxis = {
                type: "value"
            }
            let i = 0;
            for (let variable in this.variableHistory) {
                lastDayLineChartOptions.legend.data[i] = variable;
                lastDayLineChartOptions.series[i] = {
                    data: this.variableHistory[variable],
                    name: variable,
                    type: "line",
                    symbol: "emptyDiamond",
                    symbolSize: 6,
                    color: this.colors[i],
                    smooth: true,
                    step: this.chartHistory[variable]?.[i]?.[3] ?? false,
                    areaStyle: {
                        color: this.colors[i],
                        opacity: 0.03
                    },
                }
                i += 1;
            }
            return lastDayLineChartOptions;
        },

        historyVariables() {
            let historyVariables = {};
            for (let variableType in this.deviceData.variables)
                for (let variable in this.deviceData.variables[variableType])
                    if (this.deviceData.variables[variableType][variable].saveHistory === 'true' && (variableType === 'analog' || variableType === 'digital')) {
                        historyVariables[this.deviceData.variables[variableType][variable].variableId] = this.deviceData.variables[variableType][variable];
                    }
            return historyVariables;
        },

        chart1Options() {

            let chart1Options;
            chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
            if (this.targetDeviceId == "") return chart1Options;
            chart1Options.axisPointer = {
                label: {
                    formatter: ((dataTarget) => {
                        let d = new Date(dataTarget.value);
                        switch (this.session.user.dateTimeFormat) {
                            case "DD/MM/YYYY":
                                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            case "MM/DD/YYYY":
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            default:
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                        }
                    })
                }
            }
            chart1Options.xAxis = {
                min: new Date(this.startDateTime).getTime(),
                max: new Date(this.endDateTime).getTime(),
                axisLabel: {
                    formatter: ((value) => {
                        let d = new Date(value);
                        switch (this.session.user.dateTimeFormat) {
                            case "DD/MM/YYYY":
                                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            case "MM/DD/YYYY":
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            default:
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                        }
                    }),
                    textStyle: {
                        fontSize: 9
                    },
                    axisLineShow: true
                }
            }
            let i = 0;
            for (let variable in this.chartHistory) {
                chart1Options.yAxis[i] = {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: this.colors[i]
                        }
                    },
                    name: variable.substring(0, 3).toUpperCase(),
                    nameTextStyle: {
                        fontSize: 10
                    },
                    axisLabel: {
                        fontSize: 9,
                        formatter: `{value} ${(this.chartHistory[variable][i] != undefined)
                            ? this.chartHistory[variable][i][4]
                            : ''}`
                    },
                    offset: Math.floor(i / 2) * 25,
                    position: (this.chartHistory[variable][i] != undefined)
                        ? this.chartHistory[variable][i][2]
                        : (i % 2 === 0)
                            ? "left"
                            : "right"
                }
                chart1Options.legend.data[i] = variable;
                chart1Options.series[i] = {
                    data: this.chartHistory[variable],
                    name: variable,
                    yAxisIndex: i,
                    type: "line",
                    symbol: "emptyDiamond",
                    symbolSize: 6,
                    color: this.colors[i],
                    smooth: false,
                    step: this.chartHistory[variable]?.[i]?.[3] ?? false,
                    areaStyle: {
                        color: this.colors[i],
                        opacity: 0.03
                    },
                }
                i += 1;
            }
            return chart1Options;
        },
    },
    emits: ["setCurrentCompany", "getAvailableCompanies", "handleHasRol"],
    methods: {
        checkEditingRoleCallback(hasPermission) {
            this.editingPermission = hasPermission;
        },
        //SCROLL
        scrollToActiveAlarms() {
            const element = document.getElementById("activeAlarms");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.warn("El elemento con ID 'activeAlarms' no se encontró.");
            }
        },
        scrollToIncidences() {
            const element = document.getElementById("incidences");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.warn("El elemento con ID 'activeAlarms' no se encontró.");
            }
        },
        // DISPLAY
        async displayChartsByDistributionGroup(value) {
            let match = false;
            for (const key in this.historyVariables) {
                if (Object.prototype.hasOwnProperty.call(this.historyVariables, key)) {
                    const variable = this.historyVariables[key];
                    if (variable.distributionGroup === value) {
                        this.targetVariableId = variable.variableId;
                        await this.getDeviceVariableHistory();
                        match = true;
                    }
                }
            }
            if (!match) {
                if (Object.entries(this.historyVariables)[0] != undefined) {
                    this.targetVariableId = Object.entries(this.historyVariables)[0][0];
                }
            }
        },
        filterTablebyStatus() {
            if (this.selectedStatuses.length === 0) {
                this.sortedIncidenceList = this.incidenceList;
            } else {
                this.sortedIncidenceList = this.incidenceList.filter(item =>
                    this.selectedStatuses.includes(item.status.toString())
                );
            }
            this.displayIncidencesDataTable();

        },
        isActive(status) {
            return this.selectedStatuses.includes(status);
        },
        async toggleEditDeviceModal() {
            if (this.$route.params.currentDisplay === 'device') {
                this.$router.push({ params: { currentDisplay: "deviceconfig", targetEntity: this.targetDeviceId } });
            }
        },
        async toggleViewDeviceModal() {
           
           this.display.viewDeviceModal=!this.display.viewDeviceModal
           
        },
        async togglechangeDisplaySize() {
            this.fullSize = !this.fullSize;
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.positionMap) {
                        this.positionMap.invalidateSize();
                    }
                }, 300);
                const element = document.getElementById("positionMapContent");
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                    console.warn("El elemento con ID 'activeAlarms' no se encontró.");
                }
            });
        },
        async toggleEditHistoricIncidenceModal() {
            if (this.display.editHistoricModal) {
                await this.onDisplayElements();
            }
            this.display.editHistoricModal = !this.display.editHistoricModal;
        },
        async toggleEditCommentsModal() {
            if (this.display.editCommentsModal) {
                await this.onDisplayElements();
            }
            this.display.editCommentsModal = !this.display.editCommentsModal;
        },
        toggleFilter(status) {
            const index = this.selectedStatuses.indexOf(status);
            if (index > -1) {
                this.selectedStatuses.splice(index, 1);
            } else {
                this.selectedStatuses.push(status);
            }
            this.filterTablebyStatus();
        },
        onCurrentCompanyChanged(event) {
            let value = event.target.value;
            this.$router.replace({ params: { currentCompanyNifCif: value } })
            this.$emit('setCurrentCompany', value);
        },
        async changeDisplayInfo() {
            await this.getDeviceVariableHistory();
            if (this.deviceData?.variables?.dynamic?.Posicion) {
                this.positionHistory = await this.getPositionVariableHistory();
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.positionMap) {
                            this.positionMap.invalidateSize();
                            this.displayPositionMap();
                        }
                    }, 300);
                })
            }
        },
        onGoToEntity(child) {
            let view = "";

            const currentRoute = this.$router.currentRoute.value;

            switch (child.claseDcl) {
                case "3":
                    if (currentRoute.params.currentDisplay && currentRoute.params.currentDisplay === "devices") {
                        this.$router.replace({ params: { targetEntity: child.idDcl } });
                    }
                    break;

                case "2":
                    if (currentRoute.params.currentModule && currentRoute.params.currentModule === "electricalMonitoring") {
                        this.$router.replace({
                            params: { currentDisplay: "commandCenter", targetEntity: child.idDcl }
                        });
                    } else if (currentRoute.params.currentModule && currentRoute.params.currentModule === "fabric") {
                        this.$router.replace({
                            params: { currentDisplay: "machines", targetEntity: child.idDcl }
                        });
                    } else if (currentRoute.params.currentModule && currentRoute.params.currentModule === "smartLockers") {
                        this.$router.replace({
                            params: { currentDisplay: "lockers", targetEntity: child.idDcl }
                        });
                    }
                    view = "machines";
                    break;

                case "4":
                    view = "bundles";
                    break;

                case "7":
                    view = "stocks";
                    break;

                default:
                    view = "";
                    break;
            }

            if (view !== "") {
                /*  this.$router.replace({
                   params: { currentView: view, targetEntity: child.idDcl }
                 }); */
            }
        },
        async onDisplayElements() {
            await this.getHistoricIncidencesByDcl()
            this.displayIncidencesDataTable();
        },
        async onDetaiIncidence(event) {
            console.log('DETAIL WHERE ID:');
            this.targetIncidenceId = event.target.getAttribute('data-id');
            var tr = $(event.target).closest('tr');
            var row = $("#IncidencesDataTable").DataTable().row(tr);
            if (row.child.isShown()) {
                event.target.name = "chevron-down-circle-outline";
                row.child.hide();
                tr.removeClass('shown');
            } else {
                event.target.name = "chevron-up-circle-outline";
                let content = '' +
                    '<div id="resumenPedido' + this.targetIncidence.id + '" class="p-1" style="background-color: white!important" data-id="' + this.targetIncidence.id + '">' +
                    '<h2>' + this.dictionary["Resumen de la incidencia"][this.session.user.language] + '<ion-icon class= "pdf-icon btn tx-24 tx-primary m-0 ms-3 p-0" name="print-outline" data-id="' + this.targetIncidence.id + '"></ion-icon>' + '</h2>' +
                    '<h2 id="incidenceID">' + "Identificador: " + this.targetIncidence.id + '</h2>' +
                    '<div class="container">' +
                    '<ol class="progress-meter">';

                const stages = [
                    {
                        key: 'dateOpen',
                        label: `${this.dictionary["Pendiente de revisión"][this.session.user.language]}${this.targetIncidence.dateOpen ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateOpen, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateInvestigation',
                        label: `${this.dictionary["En investigación"][this.session.user.language]}${this.targetIncidence.dateInvestigation ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateInvestigation, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateProgress',
                        label: `${this.dictionary["En proceso de resolucion"][this.session.user.language]}${this.targetIncidence.dateProgress ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateProgress, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateSolved',
                        label: `${this.dictionary["Solucionada"][this.session.user.language]}${this.targetIncidence.dateSolved ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateSolved, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateClosed',
                        label: `${this.dictionary["Cerrada"][this.session.user.language]}${this.targetIncidence.dateClosed ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateClosed, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    }
                ];

                stages.forEach(stage => {
                    const done = this.targetIncidence[stage.key] !== "";
                    content += '<li class="progress-point ' + (done ? 'done' : 'todo') + '">' +
                        stage.label + '</li>';
                });

                content += '</ol></div>' + '<div class =row>'
                    + '<div class=col-md-6>';
                content +=
                    '<table style="border-collapse: collapse; width: 100%;">' +
                    '<tr>' +
                    '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
                    this.dictionary["Información de la incidencia"][this.session.user.language] + '</th>' +
                    '</tr>'


                const statusMap = {
                    "0": this.dictionary["Pendiente de revisión"][this.session.user.language],
                    "1": this.dictionary["En investigación"][this.session.user.language],
                    "2": this.dictionary["En proceso de resolucion"][this.session.user.language],
                    "3": this.dictionary["Solucionada"][this.session.user.language],
                    "4": this.dictionary["Cerrada"][this.session.user.language],

                };
                const levelMap = {
                    "0": this.dictionary["Muy baja"][this.session.user.language],
                    "1": this.dictionary["Baja"][this.session.user.language],
                    "2": this.dictionary["media"][this.session.user.language],
                    "3": this.dictionary["Critica"][this.session.user.language],
                }
                const levelDescription = levelMap[this.targetIncidence.level] || this.targetIncidence.level;
                const statusDescription = statusMap[this.targetIncidence.status] || this.targetIncidence.status;
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Identificador"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + this.targetIncidence.id + '</td>' +
                    '</tr>';
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + statusDescription + '</td>' +
                    '</tr>';
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Nivel de gravedad"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + levelDescription + '</td>' +
                    '</tr>';
                content += '<tr>' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Última actualización"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + api.parseDateUserTimezone(this.targetIncidence.dateNow, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
                        '</tr>';
                content += '<tr>' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Creado por"][this.session.user.language] + ": " + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + this.targetIncidence.createdBy + '</td>' +
                    '</tr>' + '</table>' + ' </div>';
                /*    } */

                content += ' </div>' + '<table style="border-collapse: collapse; width: 100%;">' + '<tr>' +
                    '<td style="padding:2px; text-align: left;" colspan="2">' +
                    '<h2 style="font-size: 1.5rem;">' + this.dictionary["Observaciones"][this.session.user.language] + '<ion-icon class= "add-comments btn tx-24 tx-primary m-0 ms-3 p-0" id="add-comments" name="add-circle-outline" ></ion-icon>' + '</h2>' +
                    '<table>' +
                    '<tr>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fecha"][this.session.user.language] + '</th>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Usuario"][this.session.user.language] + '</th>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Comentarios"][this.session.user.language] + '</th>' +
                    '</tr>';

                for (let key in this.targetIncidence.description) {
                    if (Object.prototype.hasOwnProperty.call(this.targetIncidence.description, key)) {
                        let observacion = this.targetIncidence.description[key];
                        content += '<tr>' +
                            '<td style="padding:2px; text-align: start;">' + api.parseDateUserTimezone(key, this.session.user, this.$dayjs) + '</td>' +
                            '<td style="padding:2px; text-align: start;">' + observacion.usuario + '</td>' +
                            '<td style="padding:2px; text-align: start;">' + observacion.texto + '</td>' +
                            '</tr>';
                    }
                }

                content += '</table>' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '</div>' + '</div>';
                row.child(content).show();

                if (this.targetIncidenceId) {
                    let detailTable = document.getElementById('detailTable' + this.targetIncidenceId);
                    if (detailTable && detailTable.parentNode) {
                        detailTable.parentNode.style.padding = 0;
                    }
                }
                tr.addClass('shown');
            }
            this.setButtonFunctions();

        },
        async onDetailActiveAlarm(activeAlarmId) {
            this.targetActiveAlarm = "";
            this.targetActiveAlarmId = activeAlarmId;
            this.targetActiveAlarm = this.activeAlarmsList.find((item) => item.id === this.targetActiveAlarmId);
            const startDataTimeHistorical = new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 48)).toISOString().replace('T', ' ').substr(0, 23)
            const endDateTimeHistorical = new Date().toISOString().replace('T', ' ').substr(0, 23)
            const dataforHistorical = {
                id: this.targetActiveAlarm.id,
                startDataTimeHistorical: startDataTimeHistorical,
                endDateTimeHistorical: endDateTimeHistorical
            }


            const getHistoricalofCondition = await this.getHistoricalofCondition(dataforHistorical)
            this.targetActiveAlarm.historicIncidences = getHistoricalofCondition
                .filter(item => item[2].length > 0 && item[2] !== this.targetActiveAlarm.incidence)
                .reduce((uniqueItems, item) => {
                    if (!uniqueItems.some(existingItem => existingItem[1] === item[2])) {
                        uniqueItems.push([item[0], item[2]]);
                    }
                    return uniqueItems;
                }, []);
            let idDCL = this.targetLockerId;
            let chartVariableDisplayData = {
                active: true,
                variableLabel: this.targetActiveAlarm.variableLabel,
                variableType: this.targetActiveAlarm.type === "0" ? "1" : "0", //modificar cuando se añadan las condiciones dinamicas      
                status: this.targetActiveAlarm.status,
                statusDate: this.targetActiveAlarm.statusDate,
                id: idDCL,
                startDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 1)).toISOString().replace('T', ' ').substr(0, 23),
                endDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() + 47)).toISOString().replace('T', ' ').substr(0, 23),
                startDataTimeHistorical: startDataTimeHistorical,
                endDateTimeHistorical: endDateTimeHistorical,
                limit: 0,
                history: getHistoricalofCondition
            };


            if (this.targetActiveAlarm.type === "0") {
                chartVariableDisplayData.upperLimit = this.targetActiveAlarm.upperLimit;
                chartVariableDisplayData.lowerLimit = this.targetActiveAlarm.lowerLimit;
                chartVariableDisplayData.hasUpperLimit = this.targetActiveAlarm.hasUpperLimit;
                chartVariableDisplayData.hasLowerLimit = this.targetActiveAlarm.hasLowerLimit;
            } else if (this.targetActiveAlarm.type === "1") {
                chartVariableDisplayData.isActive = this.targetActiveAlarm.isActive;
            }
            this.activeAlarmLineChartOptions = await this.displayVariablesChartOptions(chartVariableDisplayData);
            //this.historicActiveAlarmlineChartOptions = await this.displayHistoricChartOptions(chartVariableDisplayData);

        },
        async displayVariablesChartOptions(displayData) {
            let variableHistoricData = [];
            try {
                let response = await api.getMachineVariableHistory(displayData);
                if (response["status-code"] === "200") {
                    variableHistoricData = api.parseVariableDataFromHistoryResponse(response);
                    let value = parseFloat(variableHistoricData.value).toFixed(2);
                    let unit = variableHistoricData.unit;
                    let resultado = `${value} ${unit}`;
                    if (displayData.active === true) {
                        this.targetActiveAlarm.valueNow = resultado
                    } else {
                        this.targetAlarm.valueNow = resultado
                    }
                } else {
                    throw new Error(`Unexpected status code: ${response["status-code"]}`);
                }
            } catch (error) {
                //console.error(error);
            }
            const dates = variableHistoricData.history.map(item => item[0]);
            const statusDateTimestamp = new Date(displayData.statusDate).getTime();
            if (variableHistoricData.type == "1") {
                const dataValues = variableHistoricData.history.map(item => item[1]);
                const lowerLimit = displayData.hasLowerLimit === "true" ? parseInt(displayData.lowerLimit) : null;
                const upperLimit = displayData.hasUpperLimit === "true" ? parseInt(displayData.upperLimit) : null;
                const margin = 10;
                const minValue = Math.floor(
                    Math.min(
                        ...dataValues,
                        lowerLimit !== null ? lowerLimit : Infinity
                    ) - margin
                );

                const maxValue = Math.ceil(
                    Math.max(
                        ...dataValues,
                        upperLimit !== null ? upperLimit : -Infinity
                    ) + margin
                );



                const lineChartOptions = {
                    toolbox: {
                        feature: {
                            dataView: {
                                readOnly: true
                            },
                            dataZoom: {
                                show: true,
                                yAxisIndex: null
                            },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                pixelRatio: 2
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'time',
                        data: dates.map(date => new Date(date).getTime()),
                        axisLabel: {
                            formatter: value => {
                                const date = new Date(value);
                                // Formateo de fecha y hora con ceros a la izquierda
                                return String(date.getDate()).padStart(2, "0") + "/" +
                                    String(date.getMonth() + 1).padStart(2, "0") + "/" +
                                    String(date.getFullYear()) + " \n " +
                                    String(date.getHours()).padStart(2, "0") + ":" +
                                    String(date.getMinutes()).padStart(2, "0");
                            },
                            margin: 10,
                            fontSize: 10,
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: minValue,
                        max: maxValue,
                        axisLabel: {
                            formatter: `{value} ${variableHistoricData.unit}`
                        }
                    },
                    grid: {
                        "top": "15%",
                        "right": "5%",
                        "bottom": "3%",
                        "left": "5%",
                        "containLabel": true
                    },
                    series: [
                        {
                            name: displayData.variableLabel,
                            type: 'line',
                            data: dataValues.map((value, index) => [new Date(dates[index]).getTime(), value]),
                            symbol: 'none',
                            markLine: {
                                data: [
                                    ...(displayData.hasUpperLimit === "true" ? [{
                                        yAxis: parseInt(displayData.upperLimit),
                                        name: 'Límite superior',
                                        lineStyle: {
                                            type: 'dashed',
                                            color: 'red',
                                            width: 2
                                        }
                                    }] : []),
                                    ...(displayData.hasLowerLimit === "true" ? [{
                                        yAxis: parseInt(displayData.lowerLimit),
                                        name: 'Límite inferior',
                                        lineStyle: {
                                            type: 'dashed',
                                            color: 'red',
                                            width: 2
                                        }
                                    }] : []),
                                    {
                                        xAxis: statusDateTimestamp,
                                        name: 'Alarma',
                                        lineStyle: {
                                            type: 'solid',
                                            color: 'red',
                                            width: 2
                                        }
                                    }
                                ],
                                symbol: 'none',
                                label: {
                                    formatter: params => params.name
                                }
                            }
                        }
                    ]

                };

                return lineChartOptions;
            } else if (variableHistoricData.type == "0") {
                const dataValuesDigital = variableHistoricData.history.map(item => item[1] === "true" ? 1 : 0);
                const lineChartOptions = {
                    toolbox: {
                        feature: {
                            dataView: {
                                readOnly: true
                            },
                            dataZoom: {
                                show: true,
                                yAxisIndex: null
                            },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                pixelRatio: 2
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'time',
                        boundaryGap: false,
                        data: dates.map(date => new Date(date).getTime()),
                        axisLabel: {
                            formatter: value => {
                                const date = new Date(value);
                                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}/n ${date.getHours()}:${date.getMinutes()}`;
                            },

                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 1,
                        axisLabel: {
                            formatter: `{value} ${variableHistoricData.unit}`
                        }
                    },
                    series: [
                        {
                            name: displayData.variableLabel,
                            type: 'line',
                            data: dataValuesDigital.map((value, index) => [new Date(dates[index]).getTime(), value]),
                            symbol: 'none',
                            markLine: {
                                data: [
                                    {
                                        xAxis: statusDateTimestamp,
                                        name: 'Alarma',
                                        lineStyle: {
                                            type: 'solid',
                                            color: 'red',
                                            width: 2
                                        }
                                    }
                                ],
                                symbol: 'none',
                                label: {
                                    formatter: params => params.name
                                }
                            }
                        }
                    ]
                };
                return lineChartOptions;
            }

        },
        async getAlarmsbyDcl(selectedDcl) {
            this.activeAlarmsList = {}
            let data = {
                idDcl: selectedDcl[0].id ? selectedDcl[0].id : selectedDcl
            };
            try {
                let response = await api.getConditionsByDcl(data)

                if (response["status-code"] === "200") {

                    this.alarmsList = api.parseAlarmListFromResponse(response);
                    this.activeAlarmsList = [...this.alarmsList].filter((item) => item.status === "2" && item.isAlarm === "true");
                    this.preActive = [...this.alarmsList].filter((item) => item.status === "1");
                    this.postActive = [...this.alarmsList].filter((item) => item.status === "3");

                } else {
                    if (this.alarmsList) {
                        this.alarmsList = {}
                        this.activeAlarmsList = {}
                    }
                    throw new Error("No existen alarmas para este activo.");
                }
                this.displayActiveAlarmsDataTable()

            } catch (error) {
                // console.error(error.message);
            }
        },
        captureAndPrint(content) {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
            mywindow.document.write('</head><body >');

            mywindow.document.write(document.getElementById(content).innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            this.setButtonFunctions();

            return true;
        },
        onCopyId(event) {
            navigator.clipboard.writeText(event.target.getAttribute('data-id'));
        },
        async onModifyIncidence(event) {
            console.log('MODIFY WHERE :');
            console.log(event.target.getAttribute('data-id'));
            this.targetIncidenceId = event.target.getAttribute('data-id');
            this.toggleEditHistoricIncidenceModal();
        },
        onAddComents(event) {
            this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
            this.display.editCommentsModal = true;
        },
        onPrint(event) {
            this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
            this.captureAndPrint('resumenPedido' + this.targetIncidenceHistoryId);
        },
        // BUTTONS
        setButtonFunctions() {
            setTimeout(() => {

                $(".btnCopyId").get().forEach(btn => {
                    btn.addEventListener('click', this.onCopyId);
                });

                $(".btnDetailIncidence").get().forEach(btn => {
                    btn.addEventListener('click', this.onDetaiIncidence)
                });
                $(".btnModifyIncidence").get().forEach(btn => {
                    btn.addEventListener('click', this.onModifyIncidence);
                });
                $(".btnCopyId").get().forEach(btn => {
                    btn.addEventListener('click', this.onCopyId);
                });
                $(".add-comments").get().forEach(btn => {
                    btn.addEventListener('click', this.onAddComents);
                });
                $(".pdf-icon").get().forEach(btn => {
                    btn.addEventListener('click', this.onPrint);
                });
            }, 300);
        },
        displayIncidencesDataTable() {
            $("#IncidencesDataTable").DataTable().clear()
            for (let i in this.sortedIncidenceList) {
                $("#IncidencesDataTable").DataTable().rows.add([{
                    IncidenceHistoricId: this.sortedIncidenceList[i].id,
                    IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
                    IncidenceLevel: this.sortedIncidenceList[i].level,
                    IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel,
                    openingDate: this.sortedIncidenceList[i].dateOpen
                }]);
            }
            $("#IncidencesDataTable").DataTable().draw();
            this.setButtonFunctions();
            if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
            $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
        },
        displayActiveAlarmsDataTable() {
            const table = $("#ActiveAlarmsDataTable").DataTable();

            table.clear();

            for (let i in this.activeAlarmsList) {
                table.rows.add([{
                    activeAlarmId: this.activeAlarmsList[i].id,
                    activeAlarmName: this.activeAlarmsList[i].label,
                    activationDate: this.activeAlarmsList[i].statusDate,
                    activeAlarmSilenced: {
                        isMuted: this.activeAlarmsList[i].isMuted,
                        id: this.activeAlarmsList[i].id
                    }
                }]);
            }

            table.draw();
            this.setButtonFunctions();

            setTimeout(() => {
                const firstRadioBtn = $("#ActiveAlarmsDataTable input[type='radio']").first();
                if (firstRadioBtn.length) {
                    const firstRadioValue = firstRadioBtn.val();
                    firstRadioBtn.prop("checked", true).trigger('click');
                    this.onDetailActiveAlarm(firstRadioValue);
                }
            }, 300);
            if ($("#ActiveAlarmsDataTable_paginate").get()[0] != undefined) {
                $("#ActiveAlarmsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
            }
            $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
        },
        displayPositionMap() {
            const mapContainer = document.getElementById("positionMapContent");
            if (!mapContainer || mapContainer.offsetWidth === 0 || mapContainer.offsetHeight === 0) {
                console.error("El contenedor 'positionMapContent' no está disponible o tiene dimensiones inválidas.");
                return;
            }

            // Limpiar el mapa existente
            if (this.positionMap) {
                this.positionMap.eachLayer((layer) => {
                    this.positionMap.removeLayer(layer);
                });
                this.positionMap.off();
                this.positionMap.remove();
                this.positionMap = undefined;
            }

            // Inicializar el mapa
            var positionMap = Leaflet.map("positionMapContent");
            Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(positionMap);

            if (this.positionHistory && this.positionHistory.length > 0) {
                const iconFirst = Leaflet.divIcon({
                    className: 'custom-icon',
                    html: `<ion-icon name="flag" class="border-white tx-36 c-pointer" style="vertical-align: middle; color: green;"></ion-icon>`,
                    iconAnchor: [8, 32]
                });

                const iconLast = Leaflet.divIcon({
                    className: 'custom-icon',
                    html: `<ion-icon name="flag" class="border-white tx-36 c-pointer pb-4" style="vertical-align: middle; color: red;""></ion-icon>`,
                    iconAnchor: [8, 32]
                });

                const iconDefault = Leaflet.divIcon({
                    className: 'custom-icon',
                    html: `<ion-icon name="radio-button-on-outline" class="border-white tx-16 c-pointer" style="vertical-align: middle; color: blue; opacity: 0.8"></ion-icon>`,
                    iconAnchor: [8, 12]
                });

                // Agregar marcadores con iconos personalizados
                this.positionHistory.forEach((point, index) => {
                    const lat = point.geoJSON.geometry.coordinates[0];
                    const lon = point.geoJSON.geometry.coordinates[1];
                    const fecha = point.fecha;


                    let icon = iconDefault;
                    if (index === 0) {
                        icon = iconFirst;
                    } else if (index === this.positionHistory.length - 1) {
                        icon = iconLast;
                    }


                    Leaflet.marker([lat, lon], { icon })
                        .addTo(positionMap)
                        .bindPopup(`<div style="min-width: 180px;"><strong class=>Fecha:</strong><span> ${this.$dayjs(new
                            Date(fecha)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat + ' ' + 'HH:mm:ss')}</span></div>`);
                });


                const latLngs = this.positionHistory.map((point) => [
                    point.geoJSON.geometry.coordinates[0],
                    point.geoJSON.geometry.coordinates[1]
                ]);
                positionMap.fitBounds(latLngs);


                Leaflet.polyline(latLngs, { color: 'blue', weight: 4, opacity: 0.5 }).addTo(positionMap);
            } else {

                positionMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
            }


            const attributionControl = document.querySelector(".leaflet-control-attribution");
            if (attributionControl) attributionControl.remove();

            this.positionMap = positionMap;
        },
        // GET
        async getDeviceData(callback) {
            let response = await api.getDeviceData(this.targetDeviceId);
            this.deviceData = api.parseDeviceFromResponse(response);
            let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.deviceData.dclTypeId);
            this.deviceData.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
            if (callback && typeof callback === "function") callback(this.deviceData);
        },

        async getIncidencesByCompany() {
            const path = this.$route.path;
            const segments = path.split('/');
            let request = {
                nifCif: segments[2],
                limit: 0,
                offset: 0,

            };
            let response = await api.getIncidencesByCompany(request);
            this.incidenceTypes = api.parseIncidenceListFromResponse(response);
        },
        async getHistoricIncidencesByDcl() {
            let data = { id: this.targetDeviceId };
            let response = await api.getHistoricIncidencesByDcl(data);
            if (response['status-code'] === '200') {
                this.incidenceList = api.parseHistoricIncidenceListFromResponse(response);
            } else {
                this.incidenceList = []
            }
            this.incidenceList.forEach(incidence => {
                const matchingType = this.incidenceTypes.find(type => type.id === incidence.idIncidence);
                if (matchingType) {
                    incidence.incidenceLabel = matchingType.label;
                } else {
                    incidence.incidenceLabel = '-'
                }
            });

            this.sortedIncidenceList = this.incidenceList
        },
        // TARGET
        async targetDevice(newId) {
            this.targetDeviceId = newId;
            await this.getDeviceData();
            await this.getHistoricIncidencesByDcl();
            this.variableHistory = {};
            await this.getDeviceVariableHistory();
            await this.displayChartsByDistributionGroup('1');
            this.displayIncidencesDataTable();
            if (this.deviceData?.variables?.dynamic?.Posicion) {
                this.positionHistory = await this.getPositionVariableHistory();
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.positionMap) {
                            this.positionMap.invalidateSize();
                            this.displayPositionMap();
                        }
                    }, 300);
                })
            }
        },
        async changeChartDisplay() {
            await this.getDeviceVariableHistory();


        },
        async getDeviceVariableHistory(callback) {
            if (!this.historyVariables[this.targetVariableId]) return
            let targetAxis = (this.checkboxTargetAxisRight) ? 'right' : 'left';
            let step = this.historyVariables[this.targetVariableId].isStatus==='true'?'start':false;
            if (this.deviceData != {}) {
                let request = {
                    id: this.deviceData.id,
                    variableLabel: this.historyVariables[this.targetVariableId].label,
                    variableType: this.historyVariables[this.targetVariableId].type,
                    limit: 0,
                    offset: 0,
                    startDateTime: api.parseDateTimeToString(this.startDateTimeInput),
                    endDateTime: api.parseDateTimeToString(this.endDateTimeInput)
                }
                let response = await api.getDeviceVariableHistory(request);
                // PARSE HISTORY RESPONSE
                let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
                let rawHistory = parsedResponse.history              
                this.chartHistory[this.historyVariables[this.targetVariableId].label] = [];
                for (let i in rawHistory) {                    
                    this.chartHistory[this.historyVariables[this.targetVariableId].label][i] = [
                        // DATE
                        this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
                        // VALUE
                        new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
                        // POSITION
                        targetAxis,
                        // STEP
                        step,
                        parsedResponse.unit
                    ]
                }
                // SET DATA BOUNDRIES
                this.startDateTime = this.startDateTimeInput;
                this.endDateTime = this.endDateTimeInput;
                // SWITCH AXIS TARGET
                this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
                this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
                if (callback && typeof callback === "function") callback(this.availableDevices)
            }
        },
        async getPositionVariableHistory() {
            let request = {
                id: this.deviceData.id,
                variableLabel: this.deviceData?.variables?.dynamic?.Posicion.label,
                variableType: this.deviceData?.variables?.dynamic?.Posicion.type,
                limit: 0,
                offset: 0,
                startDateTime: api.parseDateTimeToString(this.startDateTimeInput),
                endDateTime: api.parseDateTimeToString(this.endDateTimeInput)
            }
            let response = await api.getDeviceVariableHistory(request);
            let rawHistory = api.parseVariableHistoryFromResponse(response);
            const positionHistory = [];
            const distanciaMinima = 25;  // Distancia en metros

            let lastPoint = null;

            for (let i in rawHistory) {
                const fecha = rawHistory[i][0];
                const coordenadas = api.parseStringToGeoJSON(rawHistory[i][1]);

                const lat = coordenadas.geometry.coordinates[0];
                const lon = coordenadas.geometry.coordinates[1];

                if (lastPoint) {
                    const distancia = this.calculateDistanceBetweenPoints(lastPoint.lat, lastPoint.lon, lat, lon);
                    if (distancia >= distanciaMinima) {
                        positionHistory.push({
                            fecha: fecha,
                            geoJSON: coordenadas
                        });
                        lastPoint = { lat, lon, fecha };
                    }
                } else {
                    positionHistory.push({
                        fecha: fecha,
                        geoJSON: coordenadas
                    });
                    lastPoint = { lat, lon, fecha };
                }
            }
            return positionHistory;
        },
        calculateDistanceBetweenPoints(lat1, lon1, lat2, lon2) {
            const EARTH_RADIUS = 6371e3;
            const radianLat1 = lat1 * Math.PI / 180;
            const radianLat2 = lat2 * Math.PI / 180;
            const deltaLat = (lat2 - lat1) * Math.PI / 180;
            const deltaLon = (lon2 - lon1) * Math.PI / 180;
            const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                Math.cos(radianLat1) * Math.cos(radianLat2) *
                Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return EARTH_RADIUS * c;
        },
    },
    async created() {
        
        await this.targetDevice(this.targetDeviceId);
        await this.getAlarmsbyDcl(this.targetDeviceId);
        await this.getDeviceVariableHistory();
        await this.displayChartsByDistributionGroup('1');
        await this.getIncidencesByCompany();
        await this.getHistoricIncidencesByDcl();
        this.displayIncidencesDataTable();
        if (this.deviceData?.variables?.dynamic?.Posicion) {
            this.positionHistory = await this.getPositionVariableHistory();
            this.displayPositionMap();
        }
    },
    mounted() {
        const element = document.getElementById("head-section");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        $("#IncidencesDataTable").DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.incidenceColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                //stateSave: this.dataTableOptions.stateSave,
                order: [[3, 'desc']]
            }
        );
        $("#ActiveAlarmsDataTable").DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'frtip',
                buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.activeAlarmColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                //stateSave: this.dataTableOptions.stateSave,
                order: [[2, 'desc']]
            }
        );
        $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
        this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
    }
}
</script>

<style>
#cardRelaysStatusContainer {
    display: flex;
    gap: 1rem;
}

#cardDigitalInputsContainer {
    display: flex;
    gap: 1rem;
}

.btnGoToEntity:hover {
    color: var(--main-dark) !important;
}

@media (max-width: 767px) {

    #cardOverall,
    #cardCurrentStatus,
    #cardSCC {
        padding-right: 1rem !important;
    }

    #cardRelaysStatusContainer,
    #cardDigitalInputsContainer {
        display: block;
    }
}

.btn-custom-primary {
    font-size: 12;
    border: 2px solid #3498db;
    border-left: 10px solid #3498db;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
    border: 2px solid #2ecc71;
    border-left: 10px solid #2ecc71;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
    border: 2px solid #e67e22;
    border-left: 10px solid #e67e22;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
    border: 2px solid #e74c3c;
    border-left: 10px solid #e74c3c;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
    border: 2px solid #f39c12;
    border-left: 10px solid #f39c12;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
    font-size: 12;
    border-color: #2980b9;
    background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
    border-color: #27ae60;

    background-color: #eaf6e7;
}

.btn-custom-success:hover {
    border-color: #d35400;
    background-color: #f8eae2;
}

.btn-custom-danger:hover {
    border-color: #c0392b;
    background-color: #f8d7da;
}

/* */
.btn-primary-active {
    border-color: #2980b9 !important;
    background-color: #eaf0f8 !important;
    box-shadow: inset 0 0 10px #2980b9;

    font-weight: bold;
    position: relative;
}

.btn-secondary-active {
    border-color: #27ae60 !important;
    background-color: #eaf6e7 !important;
    box-shadow: inset 0 0 10px #27ae60;

    font-weight: bold;
    position: relative;
}

.btn-success-active {
    border-color: #d35400 !important;
    background-color: #f8eae2 !important;
    box-shadow: inset 0 0 10px #d35400;

    font-weight: bold;
    position: relative;
}

.btn-danger-active {
    border-color: #c0392b !important;
    background-color: #f8d7da !important;
    box-shadow: inset 0 0 10px #c0392b;

    font-weight: bold;
    position: relative;
}

.btn-warning-active {
    border-color: #e67e22 !important;
    background-color: #fff3e0 !important;
    box-shadow: inset 0 0 10px #e67e22;

    font-weight: bold;
    position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
    content: '✓';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);


}
</style>