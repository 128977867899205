<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

      <!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Abrir incidencia'][session.user.language] }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOpenNewIncidence')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>
      <!-- CONTENT New Incidence -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">
            <form class="p-2 m-0 border rounded-5 form-material">
              <div class="row col-12 bg-menu text-white p-1 m-0 rounded">
                  <div class="row col-lg-6 col-12 p-0 m-0"> </div>
                  <div class="row col-lg-6 col-12 p-0 m-0 pe-3 justify-content-end">
                    <button type="button" @click="onCreateOpenIncidence()" id="btnSubmitCreateNewProduct"
                        class="button-ok gradient-tablas rounded-3" style="max-height: 34px; max-width: 200px;">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0">{{ dictionary['Abrir incidencia'][session.user.language] }}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
              <div class="row">
                <!-- incidencia -->
                <div class="form-group col-md-6 col-12  p-1">
                  <label class="col-12 tx-13 text-dark tx-bold">
                    {{ dictionary['Tipo incidencia'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.idIncidence" id="inputNewIncidence" @change="updateValues"
                      class="form-control form-control-line" style="color:black;">
                      <option v-for="item in incidenceList" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 text-clomos tx-14 tx-bold">
                    {{ dictionary['Nivel de gravedad'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.level" id="inputNewIncidenceLevel"
                      class="form-control form-control-line" style="color:black;">
                      <option value="0">
                        {{ dictionary['Muy baja'][session.user.language] }}
                      </option>
                      <option value="1">
                        {{ dictionary['Baja'][session.user.language] }}
                      </option>
                      <option value="2">
                        {{ dictionary['media'][session.user.language] }}
                      </option>
                      <option value="3">
                        {{ dictionary['Alta'][session.user.language] }}
                      </option>
                      <option value="4">
                        {{ dictionary['Critica'][session.user.language] }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- incidencia -->
                <div class="form-group col-md-6 col-12 p-1 ">
                  <label class="col-12 tx-13 text-dark tx-bold">
                    {{ dictionary['Tipo'][session.user.language] + ": " }}
                  </label>
                  <div class="col-12">
                    <select v-model="parentType" class="form-control form-control-line" style="color:black;"
                      @change="onChangeParentType">
                      <option value="">
                          Ninguno
                        </option>
                      <option v-if="this.$route.params.currentModule === 'devices'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="device">
                        {{ dictionary['Dispositivo'][session.user.language] }}
                      </option>
                      <option v-if="this.$route.params.currentModule === 'fabric'
                       " value="bundle">
                        {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Hueco'][session.user.language] : dictionary['Conjunto'][session.user.language] }}
                      </option>
                      <option v-if="this.$route.params.currentModule === 'fabric'
                        || this.$route.params.currentModule === 'smartLockers'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="machine">
                        {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Locker'][session.user.language] : this.$route.params.currentModule === 'electricalMonitoring'? dictionary['Centro'][session.user.language] : dictionary['Máquina'][session.user.language] }}
                      </option>
                      <option v-if="this.$route.params.currentModule === 'devices'
                        || this.$route.params.currentModule === 'fabric'
                        || this.$route.params.currentModule === 'storage'
                        || this.$route.params.currentModule === 'smartLockers'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="emplacement">
                        {{ dictionary['Emplazamiento'][session.user.language] }}
                      </option>
                    </select>
                  

                  <label v-if="parentType === 'device'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ dictionary['Dispositivo'][session.user.language] + ": " }}
                  </label>
                  <input v-if="parentType === 'device'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'device'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="device in filteredDevices" :key="device" :value="device.id"
                      :selected="device.id === targetEntityId">
                      {{ device.label }}
                    </option>
                  </select>

                  <span v-if="parentType === 'bundle'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Huecos'][session.user.language] : dictionary['Conjuntos'][session.user.language]+ ": " }}
                  </span>
                  <input v-if="parentType === 'bundle'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'bundle'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="bundle in filteredBundles" :key="bundle" :selected="bundle.id === targetEntityId"
                      :value="bundle.id">
                      {{ bundle.label }}
                    </option>
                  </select>

                  <span v-if="parentType === 'machine'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Lockers'][session.user.language] : this.$route.params.currentModule === 'electricalMonitoring'? dictionary['Centros'][session.user.language] : dictionary['Máquinas'][session.user.language]+ ": "  }}
                  </span>
                  <input v-if="parentType === 'machine'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'machine'" id="selectTargetDevice" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="machine in filteredMachines" :key="machine.id" :value="machine.id"
                      :selected="machine.id === targetEntityId">
                      {{ machine.label }}
                    </option>
                  </select>

                  <span v-if="parentType === 'emplacement'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ dictionary['Emplazamiento'][session.user.language] + ": " }}
                  </span>
                  <input v-if="parentType === 'emplacement'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'emplacement'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="emplacement in filteredEmplacements" :key="emplacement"
                      :selected="emplacement.id === targetEntityId" :value="emplacement.id">
                      {{ emplacement.label }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- descripción incidencia -->
              <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark tx-bold text-start ps-4">
                    {{ dictionary['Descripción'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea class="form-control form-control-line text-start" style="color:black;" rows="3" v-model="descriptionText"
                      readonly></textarea>
                  </div>
                </div>
                <div v-if="incidenceLockingOrder" class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark tx-bold">
                    {{ dictionary['Pedido'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <span id="inputLocking" :disabled="openNewIncidenceData.idDcl && incidenceLocker"
                      class="form-control form-control-line" style="color:black;">
                      {{ incidenceLockingOrder }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="!incidenceLockingOrder" class="row">
                <!-- orden -->
                <div class="form-group col-12 p-1 ps-4 row">
                  <label class="col-12 tx-13 text-dark tx-bold text-start ms-3">
                    {{ dictionary['Notificar a'][session.user.language] }}
                  </label>
                  <div class="col-lg-10 col-12">
                    <input v-model="newEmail" type="email" id="inputEmail" class="form-control form-control-line"
                      style="color:black;" :placeholder="dictionary['Introduce email'][session.user.language]" />
                  </div>
                  <div class="col-lg-2 col-12">
                    <button type="button" id="btnSubmitValidateEmail"
                      class="button-ok tx-14 text-white rounded-3 text-center gradient-tablas" @click="addEmail"
                      style="vertical-align: middle; width: 80%; height: 2.2rem;">
                      {{ dictionary['Validar'][session.user.language] }}
                    </button>
                  </div>
                </div>
                <div v-if="emails.length > 0" class="col-12 p-1 ps-4 row mt-3">
                  <div v-for="(email, index) in emails" :key="index" class="col-10 mb-2 d-flex align-items-center">
                    <span class="form-control form-control-line col-10" style="color:black;">
                      {{ email }}
                    </span>
                    <div class="form-group col-lg-2 col-12 m-0 p-0">
                      <ion-icon name="remove-circle-outline"
                        class="bg-danger text-white c-pointer targets-icons mt-2 m-0 p-0 border rounded-circle tx-36 text-bold"
                        style="vertical-align: middle;" @click="removeEmail(index)">
                      </ion-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- DESCRIPCIÓN -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark tx-bold text-start ms-3">
                    {{ dictionary['Observaciones'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea v-model="openNewIncidenceData.description" id="inputNewProductDescription" type="text"
                      rows="1" class="form-control form-control-line" style="color:black;" />
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OpenNewIncidenceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    incidenceTypes: {
      type: Object,

    },
    incidenceId: {
      type: String,

    },
    incidenceLocker: {
      type: String
    },
    incidenceLockingId: {
      type: String
    },
    incidenceLockingOrder: {
      type: String
    },
    incidenceStatus: {
      type: String
    }
  },
  data() {
    return {
      openNewIncidenceData: {
        idIncidence: "",
        observations: "",
        level: "",

      },
      targetEntityId: "",
      targetEntity: "",
      incidenceList: null,
      descriptionText: "",
      emailAvailable: '',
      newEmail: '',
      emails: [],
      availableDevices: [],
      availableBundles: [],
      availableMachines: [],
      availableEmplacements: [],
      parentType: "",
      searchQuery: ""
    }
  },
  watch: {
    filteredMachines(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredDevices(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredBundles(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredEmplacements(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    }
  },
  computed: {
    filteredMachines() {
      if (!Array.isArray(this.availableMachines) || this.availableMachines.length === 0) {
        return [];
      }
      return this.availableMachines.filter(machine => {
        const label = machine.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredDevices() {
      if (!Array.isArray(this.availableDevices) || this.availableDevices.length === 0) {
        return [];
      }
      return this.availableDevices.filter(device => {
        const label = device.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredBundles() {
      if (!Array.isArray(this.availableBundles) || this.availableBundles.length === 0) {
        return [];
      }
      return this.availableBundles.filter(bundle => {
        const label = bundle.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });


    },
    filteredEmplacements() {
      if (!Array.isArray(this.availableEmplacements) || this.availableEmplacements.length === 0) {
        return [];
      }
      return this.availableEmplacements.filter(emplacement => {
        const label = emplacement.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },

  },
  emits: ["toggleOpenNewIncidence"],
  methods: {
    async onCreateOpenIncidence() {
      let valid = true;
      if (this.openNewIncidenceData.idIncidence === "" || this.openNewIncidenceData.idIncidence === undefined) {
        $("#inputNewIncidence").addClass("missing");
        valid = false;
      }
      if (this.openNewIncidenceData.description === "" || this.openNewIncidenceData.description === undefined) {
        $("#inputNewProductDescription").addClass("missing");
        valid = false;
      }

      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }
      if (this.parentType===""){
        this.openNewIncidenceData.idDcl = ""

      }else if (this.targetEntityId) {
        this.openNewIncidenceData.idDcl = this.targetEntityId
      }

      this.openNewIncidenceData.observations = this.openNewIncidenceData.description
      if (this.incidenceLockingId) {
        this.openNewIncidenceData.incidenceLockingId = this.incidenceLockingId
      }
      if (this.emails) {
        this.openNewIncidenceData.emails = this.emails
      }
      try {

        let response = await api.postNewHistoricIncidence(this.openNewIncidenceData);
        if (response["status-code"] === "201") {

          this.$emit('toggleOpenNewIncidence');
          window.alert(this.dictionary["La incidencia ha sido creada satisfactoriamente"][this.session.user.language]);
        } else {
          window.alert("Error al crear la incidencias.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert(this.dictionary["Error al crear la incidencia"][this.session.user.language]);
      }
    },
    updateValues() {
      const selectedIncidence = this.incidenceList.find(item => item.id === this.openNewIncidenceData.idIncidence);
      if (selectedIncidence) {
        this.openNewIncidenceData.level = selectedIncidence.level;
        this.descriptionText = selectedIncidence.description,
          this.emailAvailable = selectedIncidence.email === 'true' ? true : false
      }
    },
    addEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailPattern.test(this.newEmail) && !this.emails.includes(this.newEmail)) {
        this.emails.push(this.newEmail);
        this.newEmail = '';
      } else {
        alert(this.dictionary['Por favor, introduce un email válido.'][this.session.user.language]);
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    onChangeParentType() {
      this.targetEntity = {};
      this.searchQuery = ''
      if (this.parentType === 'device') {
        this.getDeviceList();
      }
      if (this.parentType === 'bundle') {
        this.getBundleList();
      }
      if (this.parentType === 'machine') {
        this.getMachineList();
      }
      if (this.parentType === 'emplacement') {
        this.getEmplacementList();
      }
    },
    async getDeviceList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      let allAvailableDevices = api.parseDevicesFromResponse(response);
      this.availableDevices = [];
      for (let i in allAvailableDevices) {
        if (allAvailableDevices[i].id != this.targetDeviceId) this.availableDevices.push(allAvailableDevices[i])
      }
      if (this.availableDevices[0] != undefined) {
        this.targetEntityId = this.availableDevices[0].id;
        this.targetEntity = this.availableDevices[0];
      }
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getBundleList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.availableBundles = api.parseBundlesFromResponse(response);
      if (this.availableBundles[0] != undefined) {
        this.targetEntityId = this.availableBundles[0].id;
        this.targetEntity = this.availableBundles[0];
      }
      if (callback && typeof callback === "function") callback(this.availableBundles);
    },
    async getMachineList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.availableMachines = api.parseMachinesFromResponse(response);
      if (this.availableMachines[0] != undefined) {
        this.targetEntityId = this.availableMachines[0].id;
        this.targetEntity = this.availableMachines[0];
      } if (callback && typeof callback === "function") callback(this.availableMachines);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.availableEmplacements = api.parseEmplacementsFromResponse(response);
      if (this.availableEmplacements[0] != undefined) {
        this.targetEntityId = this.availableEmplacements[0].id;
        this.targetEntity = this.availableEmplacements[0];
      } if (callback && typeof callback === "function") callback(this.availableEmplacements);
    },
    async getIncidencesByCompany() {
      let request = {
        nifCif: this.$route.params.currentCompanyNifCif,
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceList = api.parseIncidenceListFromResponse(response);
      this.openNewIncidenceData.idIncidence= this.incidenceList[0].id
      this.openNewIncidenceData.level  = this.incidenceList[0].level
      this.descriptionText= this.incidenceList[0].description
    },

  },
  async created() {

   
      await this.getIncidencesByCompany();
    

    

      await this.getEmplacementList();

   

  },

}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>