<template>
  <!-- SECTION SiteMap -->
  <div id="siteMapSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-1 pb-1 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select v-if="this.$route.params.currentDisplay === 'emplacements'" :value="currentCompany.nifCif"
                    class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>
                <div class="row col-xl-3 col-lg-3 col-md-5 col-12 justify-content-start m-0 p-0 pt-1">
                  <button @click="onChangeDisplayType('map')" :title="dictionary['Mapa'][session.user.language]"
                    :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="map-outline"
                          :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="pt-0 tx-18 hydrated md" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                  <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                    :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="list-outline"
                          :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="documents-outline"
                          :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Emplazamientos"][session.user.language] }}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                    :title="dictionary['Añadir emplazamiento'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleNewEmplacementModal">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir emplazamiento'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button v-if="editingPermission" id="btnDisplayEditEraseModal" style="width: 30px; max-height: 30px;"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="toggleConfigurationView"
                    :title="dictionary['Editar emplazamiento'][session.user.language]">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="pt-0 me-0 tx-18 hydrated"
                          :title="dictionary['Editar emplazamiento'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" style="width: 30px; max-height: 30px;"
                    :title="dictionary['Actualizar'][session.user.language]"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="refresh-outline" class="pt-0 me-0 tx-18 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>

            </div>
          </div>

          <!-- CONTENT Map -->
          <div v-show="!displayType.includes('map')" :class="fullSize ? 'col-lg-12' : 'col-lg-4'"
            class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1 c-pointer" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div id="emplacementsMapContent" class="col-12 bg-caja rounded-5 shadow"
              :style="displayType.includes('cards') && displayType.includes('table') ? 'min-height: 80vh;' : 'min-height: 480px;'"
              style="z-index: 0; height: auto; border-top: solid 5px #005793; border-bottom: solid 2px #008db4; margin-top: -20px;">
            </div>
          </div>
          <!-- CONTENT Cards -->
          <div v-show="!displayType.includes('cards')" id="siteCardsContent"
            :class="fullSize ? 'col-lg-12' : 'col-lg-8'" class="col-8 m-0 p-2 h-auto"
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="p-0 text-end text-dark" style="z-index: 1;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1 c-pointer" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div class="row bg-caja rounded-5 p-0 me-0 ms-0 shadow" :class="fullSize ? 'ms-1 me-3' : 'ms-0'"
               style="min-height: 480px; z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:-20px;">
              <div v-for="emplacement in emplacementCardsByPages[emplacementCardsPage]" :key="emplacement"
                class="col-xl-2 col-md-6 col-12 m-0 p-2 pt-2">
                <h6 class="tx-left text-white text-center text-nowrap m-0 p-2 ps-1 pt-2 bg-lat rounded-top-5">
                  <span id="cardLabel" :data-id="emplacement.id" class="m-auto tx-11 c-pointer btnTargetEmplacement">{{
                    (emplacement.label.length > 18) ? (emplacement.label.substring(0, 18) + '...') : emplacement.label
                  }}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom m-0 p-2 h-auto" style="border-color: #ccc !important; ">
                  <img :src="displayMyEmplacementImage(emplacement.imgSrc)" :data-id="emplacement.id"
                    alt="Emplacement Card Picture" class="btnTargetEmplacement col-12 m-0 p-0 mb-2">
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-11 tx-bold">{{ dictionary['Localización'][session.user.language] + ": " }}</span>
                    <span class="tx-10 m-auto">{{ (emplacement.location.length > 100) ?
                      (emplacement.location.substring(0, 100) + '...') : emplacement.location }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 pt-0 p-2">
                <div v-for="page, index in emplacementCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-13"
                  :class="(emplacementCardsPage === index) ? 'border-secondary' : ''"
                  style="height:1.5rem; width:1.5rem;" @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- CONTENT table -->
          <div v-show="!displayType.includes('table')" :style="fullHeight ? 'min-height: 80vh;' : 'min-height: 480px;'"
            class="row col-lg-12 col-md-12 col-12 ps-4 p-3 pt-0 pb-0  mt-2 h-auto">
            <div id="tableContent" class="col-12  table-responsive bg-caja m-0 p-3 mt-3 mb-1 rounded-5 shadow"
              style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="emplacementsDataTable" class="table table-striped p-0 pb-3 bg-caja overflow-auto">
                <thead class="bg-lat tx-14 p-0 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in emplacementsColumns" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>



          <div v-show="!displayType.includes('cards') && this.targetEmplacementId === ''" class="p-3">
            <span class="tx-13">
              {{ dictionary['No hay ' + ((this.$route.params.currentDisplay === "emplacements") ? 'emplazamientos' :
                'centros de mando') + ' disponibles'][session.user.language] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL New Emplacement -->
  <NewEmplacementModal v-if="display.newEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :currentCompany="currentCompany" v-on:toggleNewEmplacementModal="toggleNewEmplacementModal">
  </NewEmplacementModal>

  <div v-if="display.configurationView">
    <EmplacementsConfigurationComponent :session="session" :dictionary="dictionary" :countries="countries"
      :timeZones="timeZones" :visibility="visibility" :currentCompany="currentCompany"
      :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
      v-on:toggleConfigurationView="toggleConfigurationView">
    </EmplacementsConfigurationComponent>
  </div>
</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import NewEmplacementModal from '@/components/emplacements/NewEmplacementModal';
import EmplacementsConfigurationComponent from '@/components/emplacements/EmplacementsConfigurationComponent';

// Clomos
import api from '@/services/api';

export default {
  name: "EmplacementsComponent",
  components: {
    EmplacementsConfigurationComponent,
    NewEmplacementModal
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      await this.getEmplacementList();
      this.displayEmplacementsMap();
      this.displayEmplacementsDataTable();
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";
      this.setButtonFunctions();
    },
    displayType: {
      handler: async function (newValue) {
       
        if (newValue.includes("cards") && newValue.includes("map") && !newValue.includes("table")) {
          this.fullHeight = true;
        } else {
          this.fullHeight = false;
        }
        const currentDisplay = this.$route.params.currentDisplay;
        const module = this.$route.params.currentModule;
        let moduleData = JSON.parse(localStorage.getItem(module)) || {};
        moduleData[currentDisplay] = moduleData[currentDisplay] || {};
        moduleData[currentDisplay].displayType = newValue;
        localStorage.setItem(module, JSON.stringify(moduleData));
      },
      deep: true
    },
    fullSize: async function (newValue) {
      const currentDisplay = this.$route.params.currentDisplay;
      const module = this.$route.params.currentModule;
      let moduleData = JSON.parse(localStorage.getItem(module)) || {};
      moduleData[currentDisplay] = moduleData[currentDisplay] || {};
      moduleData[currentDisplay].fullSize = newValue;
      localStorage.setItem(module, JSON.stringify(moduleData));
    },
    fullHeight(newValue) {
      const dataTable = $("#emplacementsDataTable").DataTable();
      dataTable.page.len(newValue ? 20 : 10).draw();
    }
  },
  data() {
    return {
      // MAP
      emplacementsColumns: [
       
        {
          text: "Nombre", data: "emplacementLabel", width: "20%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnTargetEmplacement c-pointer'>" + data.label + "</span>"
          }
        },
        {
          text: "Localización", data: "emplacementLocation", width: "20%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data + " class='text-start tx-12 w-100'>" + data + "</span>"
          }
        },
        {
          text: "Tipo", data: "emplacementType", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data + " class='text-start tx-12 w-100'>" + data + "</span>"
          }
        },
        {
          text: "Coordenadas", data: "emplacementGeoJSON", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            if (data.geometry != undefined && data.geometry.type === "Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + Number(data.geometry.coordinates[0]).toFixed(3) + "</span> <span class='me-2 ms-2 tx-primary'>Lon:</span> <span>" + Number(data.geometry.coordinates[1]).toFixed(3)
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + 0 + "</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> " + 0
          }
        }
      ],
      targetEmplacementId: this.$route.params.targetEntity,
      emplacementList: [],
      emplacementsMap: undefined,
      displayType: [],
      emplacementCardsPage: 0,
      display: {
        configurationView: false
      },
      fullSize: false,
      editingPermission: false,
      fullHeight: false,
      targetEmplacement: []
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: { lng: 0, lat: 0 },
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.emplacementList[1] != undefined) { 
        for (let i in this.emplacementList) if (this.emplacementList[i].geoJSON.geometry != undefined && this.emplacementList[i].geoJSON.geometry.type === "Point") {
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] <= l || l === undefined) {
            l = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r + l) / 2;
        viewpoint.center.lat = (t + b) / 2;
      }
      else if (this.emplacementList[0] != undefined) { // Single emplacement
        viewpoint.center = { lng: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[0]), lat: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[1]) };
      }
      return viewpoint;
    },
    emplacementCardsByPages() {
      let emplacementCardsByPages = []
      let i = 0;
      while (i * 6 < this.emplacementList.length) {
        emplacementCardsByPages.push(this.emplacementList.slice(i * 6, i * 6 + 6));
        i++;
      }
      return emplacementCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies", "handleHasRol"],
  methods: {
    // DISPLAY   
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.emplacementsMap) {
            this.emplacementsMap.invalidateSize();
          }
        }, 300);

      });
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "map") {
          this.$nextTick(() => {
            this.displayEmplacementsMap();
          });
        } else if (type === "table") {

          this.$nextTick(() => {
            this.displayEmplacementsDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);
        this.displayType = [...this.displayType];

      }


      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.emplacementCardsPage = index;
      this.setButtonFunctions();
    },
    displayEmplacementsDataTable() {
      if ($.fn.DataTable.isDataTable("#emplacementsDataTable")) {
        $("#emplacementsDataTable").DataTable().clear().destroy();
      }
      const table = $("#emplacementsDataTable").DataTable({
        pagingType: 'full_numbers',
        pageLength: this.fullHeight ? 20 : 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.emplacementsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[0, 'asc']],
      });
      for (let i in this.emplacementList) {
        table.rows.add([{
          emplacementId: this.emplacementList[i].id,
          emplacementLabel: this.emplacementList[i],
          emplacementLocation: this.emplacementList[i].location,
          emplacementType: this.emplacementList[i].type,
          emplacementGeoJSON: this.emplacementList[i].geoJSON,
        }]);
      }
      table.draw();
      this.setButtonFunctions();     
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
     
      
    },
    async displayEmplacementsMap() {
      const mapContainer = document.getElementById("emplacementsMapContent");
      if (!mapContainer || mapContainer.offsetWidth === 0 || mapContainer.offsetHeight === 0) {       
        return;
      }
      if (this.emplacementsMap) {
        this.emplacementsMap.eachLayer((layer) => {
          this.emplacementsMap.removeLayer(layer);
        });
        this.emplacementsMap.off();
        this.emplacementsMap.remove();
        this.emplacementsMap = undefined;
      }

      var emplacementsMap = Leaflet.map("emplacementsMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(emplacementsMap);

      var siteMarkersGroup = new MarkerClusterGroup();
      emplacementsMap.addLayer(siteMarkersGroup);

      let emplacementsMapListForGroup = [];
      siteMarkersGroup.addLayers(
        this.emplacementList.map((m) => {
          const options = { title: m.label, clickable: true, draggable: false };
          let coordinates = [0, 0];


          coordinates = [
            m.geoJSON.geometry.coordinates[0],
            m.geoJSON.geometry.coordinates[1],
          ]

          let emplacement =
            m.emplacement && m.emplacement !== ""
              ? m.emplacement
              : this.dictionary["Sin localizar"][this.session.user.language];


          function getIconType(activeAlarms, openedIncidences) {
            if (activeAlarms !== '0' && openedIncidences !== '0') return 'location';
            if (activeAlarms !== '0' && openedIncidences === '0') return 'warning';
            if (activeAlarms === '0' && openedIncidences !== '0') return 'alert-circle';
            return 'location';
          }

          const iconType = getIconType(m.activeAlarms, m.openedIncidences);
          const color =
            iconType === 'warning' || iconType === 'alert-circle'
              ? 'red'
              : iconType === 'location' && m.activeAlarms === '0' && m.openedIncidences === '0'
                ? 'green'
                : 'red';

          const iconHTML = `
        <ion-icon name="${iconType}" data-id="${m.id}" 
          class="btnTargetMachine border-white tx-36 c-pointer" 
          style="vertical-align: middle; color: ${color};">
        </ion-icon>`;
          // Icono personalizado como elemento HTML
          const customIcon = Leaflet.divIcon({
            html:
              iconHTML,
            className: "", //esto evita clases por defecto de leaflet
            iconSize: [52, 52], //no tiene efecto
          });

          const marker = Leaflet.marker(coordinates, { ...options, icon: customIcon });
          marker.on("mouseover", function () {
            marker.openPopup();
          });
          marker.on("mouseout", function () {
            marker.closePopup();
          });
          marker.on("click", (e) => {
            this.handleMarkerClick(e);
          });

          // Contenido del popup
          marker.bindPopup(
            `<div class="d-flex flex-column" style="min-width: 9rem;">
          <span data-id="${m.id}" class="btnTargetMachine c-pointer tx-bold tx-grey m-0 p-0">${m.label}</span>
          <div class="m-0 p-0 d-flex flex-row justify-content-between">
            <text class="w-100">${emplacement}</text>
          </div><div class="m-0 p-0 row justify-content-start">           
            <text class="w-100">Número de incidencias: ${m.openedIncidences}</text>
            <text class="w-100"> Número de alarmas: ${m.activeAlarms}</text>
          </div>
        </div>`
          );

          emplacementsMapListForGroup.push(marker);
          return marker;
        })
      );
      siteMarkersGroup.options.iconCreateFunction = function (cluster) {
        const markers = cluster.getAllChildMarkers();
        let hasRedMarker = false;
        markers.forEach((marker) => {
          if (marker.options.icon.options.html.includes('color: red')) {
            hasRedMarker = true;
          }
        });

        // Crear ícono de agrupación basado en el tipo de marcador
        const clusterColor = hasRedMarker ? 'red' : 'green';
        return Leaflet.divIcon({
          html: `
      <div style="background-color: ${clusterColor}; border-radius: 50%; 
                  width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; 
                  color: white; font-size: 16px; border: 2px  ; transform: translate(-15px, 0);"">
        ${markers.length}
      </div>
    `,
          className: '',

        });
      };

      this.$nextTick(() => {
        if (emplacementsMapListForGroup[0] != undefined) {
          const emplacementsMapGroup = Leaflet.featureGroup(emplacementsMapListForGroup);
          emplacementsMap.fitBounds(emplacementsMapGroup.getBounds());
          if (emplacementsMapGroup.getLayers().length < 2) {
            this.$nextTick(() => {
              const currentZoom = emplacementsMap.getZoom();
              emplacementsMap.setZoom(currentZoom - 3);
            });
          }
        } else {
          emplacementsMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
        }
      });
      this.$nextTick(() => {
        emplacementsMap.invalidateSize();
      });
      const attributionControl = document.querySelector(".leaflet-control-attribution");
      if (attributionControl) attributionControl.remove();
      this.emplacementsMap = emplacementsMap;
    },
    async handleMarkerClick(e) {
      this.targetEmplacementId = e.target._icon.querySelector(".btnTargetMachine").getAttribute("data-id");
     
      if (this.$route.path.includes('electricalMonitoring')) {
        const currentPath = this.$route.path;
        const commandCenterPath = 'commandCenters/' + this.targetEmplacementId;
        if (!currentPath.includes(commandCenterPath)) {
         
          this.$router.push({ params: { currentDisplay: "commandCenters", targetEntity: this.targetEmplacementId } });
        }
      } else if (this.$route.path.includes('smartLockers')) {
        await this.getSingleEmplacementData();
        const keys = Object.keys(this.targetEmplacement.children);
        if (keys[0]) {     
        
          this.$router.push({ params: { currentDisplay: "locker", targetEntity:  keys[0] } });
        }
      } else {
        this.$router.push({ params: { targetEntity: this.targetEmplacementId } });
      }
    },
    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.emplacementsMap) {
            this.emplacementsMap.invalidateSize();
          }
        }, 300);

      });
    },
    async toggleNewEmplacementModal() {
      if (this.display.newEmplacementModal) {
        await this.getEmplacementList();
        this.displayEmplacementsDataTable();
      }
      this.display.newEmplacementModal = !this.display.newEmplacementModal;
    },

    async toggleConfigurationView() {
      if (this.display.configurationView) {
        await this.getEmplacementList();
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.emplacementsMap) {
              this.emplacementsMap.invalidateSize();
              this.displayEmplacementsMap()
            }
          }, 300);
          this.$nextTick(() => {
            $("#emplacementsDataTable").DataTable(
              {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                  { "extend": 'colvis', "className": 'btn' },
                  { "extend": 'copy', "className": 'btn' },
                  { "extend": 'csv', "className": 'btn' },
                  { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.emplacementsColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                stateSave: this.dataTableOptions.stateSave,
              }
            )
            $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);

            this.displayEmplacementsDataTable();
          });
        });

        if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
        setTimeout(() => {
          this.setButtonFunctions();
        }, 100);
        if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
        else this.targetEmplacementId = "";
      }
      this.display.configurationView = !this.display.configurationView;
    },
    async refreshView() {
      await this.getEmplacementList();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.emplacementsMap) {
            this.emplacementsMap.invalidateSize();
            this.displayEmplacementsMap()
          }
        }, 300);
        this.$nextTick(() => {
          this.displayEmplacementsDataTable();
        });
      });

      if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";

    },
    displayMyEmplacementImage(src) {
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetEmplacement);
        });
        $(".btnShowEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onShowEmplacement);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);

      if (response && response['status-code'] === '200' && Array.isArray(response.plantas) && response.plantas.length > 0) {
        this.emplacementList = api.parseEmplacementsFromResponse(response);
      } else {
        if (this.emplacementList.length > 0) {
          let previousCompanyNifCif = this.emplacementList[0].nifCif;
          this.$router.replace({ params: { currentCompanyNifCif: previousCompanyNifCif } });
          this.$emit('setCurrentCompany', previousCompanyNifCif);
        }

      }

      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getSingleEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacement = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editEmplacementData);
    },
    // TARGET 
    async onTargetEmplacement(event) {
      console.log(this.$router.params)
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      if (this.$route.path.includes('electricalMonitoring')) {
        const currentPath = this.$route.path;
        const commandCenterPath = 'commandCenters/' + this.targetEmplacementId;
        if (!currentPath.includes(commandCenterPath)) {
         
          this.$router.push({ params: { currentDisplay: "commandCenters", targetEntity: this.targetEmplacementId } });
        }
      } else if (this.$route.path.includes('smartLockers')) {
        await this.getSingleEmplacementData();
        const keys = Object.keys(this.targetEmplacement.children);
        if (keys[0]) {
         
          this.$router.push({ params: { currentDisplay: "locker", targetEntity:  keys[0] } });
        }
      } else if( this.$route.path.includes('devices')){
        this.$router.push({ params: { currentDisplay: "devices"} });
      } else if(this.$route.path.includes('fabric')){
        this.$router.push({ params: { currentDisplay: "machines", targetEntity: this.targetEmplacementId } });
      }
      else {
        this.$router.push({ params: { targetEntity: this.targetEmplacementId } });
      }
    },
    // SHOW
    onShowEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.push({ params: { targetEntity: this.targetEmplacementId } });
      this.editRequest = false;
      this.toggleEditEmplacementModal();
    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    }
  },
  async created() {
    const currentDisplay = this.$route.params.currentDisplay;
    const module = this.$route.params.currentModule;
    const moduleData = JSON.parse(localStorage.getItem(module));
    if (moduleData && moduleData[currentDisplay]) {
      const storedData = moduleData[currentDisplay];
      if (storedData.fullSize !== undefined) {
        this.fullSize = storedData.fullSize;
      }
      if (storedData.displayType !== undefined) {
        this.displayType = storedData.displayType;
        if (!this.displayType.includes('map')) {
          this.displayEmplacementsMap();
        }
      }
    }
    await this.getEmplacementList()
    if (!this.displayType.includes('table')) {
    this.displayEmplacementsDataTable();
    }
    this.$emit('getAvailableCompanies', this.displayEmplacementsMap());
    if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
    this.setButtonFunctions();
  },
  mounted() {
   this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetEmplacement:hover,
.btnEditEmplacement:hover,
.btnDeleteEmplacement:hover {
  color: var(--main-dark) !important;
}

#cardLabel:hover {
  color: var(--main-white) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

/* esto pasar a clomos assets */
.bg-lat {
  background: rgb(0, 25, 113);
  background: linear-gradient(90deg, rgba(0, 25, 113, 1) 35%, rgba(0, 37, 119, 1) 100%);
}

.bg-head {
  background: rgb(0, 40, 120);
  background: linear-gradient(90deg, rgba(0, 40, 120, 1) 35%, rgba(0, 148, 183, 1) 100%);
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1px 3px !important;
  /* e.g. change 8x to 4px here */
}
</style>