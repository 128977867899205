<template>
  <!-- SECTION Machines-->
  <div id="machinesSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-6 col-lg-7 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="document-outline" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ "Informes" }}
                  </h6>
                  <select v-if="this.$route.params.currentDisplay === 'machines'" :value="currentCompany.nifCif"
                    class="form-control m-0 p-0 text-nowrap bg-white border me-3"
                    style="border: solid 2px white ; color: black; min-width:110px; max-width: 240px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                  <select v-if="this.$route.params.currentDisplay === 'machines'" :value="targetEmplacementId"
                    class="form-control m-0 p-0 text-nowrap bg-white border me-3"
                    style="border: solid 2px white ; color: black; min-width:110px; max-width: 240px; height: 34px;"
                    @change="onEmplacementChanged">
                    <option v-for="emplacement in emplacementList" :key="emplacement.nifCif" :value="emplacement.id">
                      {{ emplacement.label }}
                    </option>
                  </select>
                </div>
                <!-- <div class="row col-xl-2 col-lg-3 col-md-3 col-12 justify-content-start m-0 p-0 pt-1">
                  <button @click="onChangeDisplayType('map')" :title="dictionary['Mapa'][session.user.language]"
                    :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="map-outline"
                          :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="text-dark pt-0 tx-18 hydrated md" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                    :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="list-outline"
                          :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="border-white tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="documents-outline"
                          :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="border-white tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div> -->
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-9 col-8 justify-content-end">

                </div>
                <div class="row col-xl-2 col-lg-3 col-md-2 col-4 justify-content-start p-0 m-0 pt-1">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                    :title="dictionary['Añadir máquina'][session.user.language]" style="width: 30px; max-height: 30px;"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="toggleNewOrderModal">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir máquina'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>
                  <button id="btnDisplayEditEraseModal" :title="dictionary['Actualizar'][session.user.language]"
                    style="width: 30px; max-height: 30px;" class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="refreshView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="refresh-outline" class="pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <!-- CONTENT table -->
          <div id="machinesTableContent" v-show="!displayType.includes('table')"
            :style="fullHeight ? 'min-height: 80vh;' : 'min-height: 480px;'"
            :class="fullSize ? 'col-lg-12' : 'col-lg-12'" class="row col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja rounded-5 pt-2 shadow table-responsive"
              style=" z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:20px;">
              <table id="reportsDataTable" class="table table-striped pt-0 pb-3 bg-caja">
                <thead class="bg-lat tx-14 p-0 text-white text-center ">
                  <tr>
                    <th v-for="column in reportsColumns" :key="column.data" :width="column.width" class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- MODAL New Machine -->
  <NewOrderModal v-if="display.newOrderModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" v-on:toggleNewOrderModal="toggleNewOrderModal">
  </NewOrderModal>
  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteReportModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="'Informe a eliminar' + ': '" :alertTarget="reportTodelete" :alertTexts="''"
    v-on:acceptAlert="onDeleteReport" v-on:dismissAlert="toggleDeleteReportsModal">
  </TextAlertModal>


</template>

<script>
import $ from 'jquery';
import NewOrderModal from '@/components/electricalMonitoring/NewOrderModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ReportsComponent",
  components: { NewOrderModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentCompany: async function () {
      this.displayReportsDataTable();
      await this.getReportsByCompany()
    },


  },
  data() {
    return {
      reportsColumns: [
        {
          text: "Informe", data: "reportId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id=" + data + " class='btnDetailLocking btn tx-20 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {
          text: "Nombre", data: "reportName", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            return "<span data-id=" + data + " class='text-start tx-12 tx-bold w-100  c-pointer'>" + data + "</span>"
          }
        },


        {
          text: "Fecha", data: "report", width: "15%", className: " tx-left align-middle",
          render: (data) => {
            return "<span class='text-start'>" + api.parseDateUserTimezone(data.creationDateTime, this.session.user, this.$dayjs) + "</span>"

          }
        },
        {
          text: "Editar", data: "report", width: "8%", className: "tx-left  align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data.id + " class='c-pointer btn tx-18 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "report", width: "5%", className: "tx-left align-middle",
          render: (data) => {
            return (
              "<ion-icon name='trash-outline' data-id='" + data.id + "' data-idOrder='" + data.id + "' class='btnDeleteLocking btn tx-18 tx-danger m-0 p-0'></ion-icon>"
            )
          }
        },
      ],
      targetEmplacementData: {},
      targetMachineId: this.$route.params.targetEntity,
      machineList: [],
      emplacementList: [],
      machinesMap: undefined,
      displayType: [],
      machineCardsPage: 0,
      display: {
        showEverything: true,
        configurationView: false,
        newOrderModal: false,
        deleteReportModal: false
      },
      linkAction: "link",
      emplacementLinkedId: '',
      fullSize: false,
      editingPermission: false,
      fullHeight: false,
      deviceTypeList: {},
      machineTypeList: {},
      reportsList: {},
      reportTodelete: ''
    }
  },
  emits: ["setCurrentCompany", "setEmplacement", "getAvailableCompanies", "handleHasRol"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    displayReportsDataTable() {
      $("#reportsDataTable").DataTable().clear()
      for (let i in this.reportsList) {

        const parsedSummary = JSON.parse(this.reportsList[i].summary); // Analiza summary


        $("#reportsDataTable").DataTable().rows.add([{
          reportId: this.reportsList[i].id,
          report: this.reportsList[i],
          reportName: parsedSummary[0].Nombre
        }]);
      }
      $("#reportsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($("#reportsDataTable").get()[0] != undefined) $("#reportsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewOrderModal() {
      if (this.display.newOrderModal) {

        await this.getReportsByCompany();
        this.displayReportsDataTable();
      }
      this.display.newOrderModal = !this.display.newOrderModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetMachine);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".btnDetailLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailLocking)
        });
        $(".pdf-icon").get().forEach(btn => {
          btn.addEventListener('click', this.onPrint);
        });
        $(".btnDeleteLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteReport);
        });
      }, 300);
    },
    async toggleDeleteReportsModal() {
      if (this.display.deleteReportModal) {
        await this.getReportsByCompany();
        this.displayReportsDataTable();
      }
      this.display.deleteReportModal = !this.display.deleteReportModal;
    },
    async onDeleteReport(event) {
      if (!this.display.deleteReportModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.reportTodelete = event.target.getAttribute('data-id');

      }
      else {
        let response = await api.deleteOrder(this.reportTodelete);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewLockingModal');
          window.alert("La reserva ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la reserva.");
        }
      }
      this.toggleDeleteReportsModal();
    },
    onPrint(event) {
      let id = event.target.getAttribute('data-id');
      this.captureAndPrint('resumenPedido' + id);
    },
    captureAndPrint(content) {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
      mywindow.document.write('</head><body >');

      mywindow.document.write(document.getElementById(content).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      this.setButtonFunctions();

      return true;
    },
    async onDetailLocking(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'))
      let reportId = event.target.getAttribute('data-id')
      let selectedReport = this.reportsList.filter(type => type.id === reportId);
      const parsedSummary = JSON.parse(selectedReport[0].summary);
      const report = parsedSummary[0];
      var tr = $(event.target).closest('tr');
      var row = $("#reportsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = `
        <div id="resumenPedido${selectedReport[0].id}" 
            class="border border-2 p-2" 
            style="background-color: white !important; border-color: #0866C6 !important;">
          <h2 class="mt-2">
            Informe: ${report.Nombre}
            <span class="ms-4">
              <ion-icon 
                class="pdf-icon btn tx-24 text-black m-0 p-0" 
                id="pdf-icon" 
                name="print-outline" 
                data-id="${selectedReport[0].id}">
              </ion-icon>
              <ion-icon 
                class=" btn tx-24 text-black m-0 p-0"           
                name="mail-outline" 
                data-id="${selectedReport[0].id}">
              </ion-icon>
            </span>
          </h2>` +
          '<span class="ms-4">' + report.Descripcion + '</span>' +
          '<table class="p-1 pt-4" style="border-collapse: collapse; width: 100%;">' +
          '<tr>' +
          '<td colspan="2">' +
          '<div class="container">' +
          '<h4 style="font-size: 1.5rem;">' + "Detalle de los dispositivos" + '</h4>' +
          '<table>' +
          '<tr>' +
          '<th style="padding: 8px; text-align: left; background-color: #f2f2f2; min-width: 200px;">Dispositivo</th>' +
          '<th style="padding: 8px; text-align: left; background-color: #f2f2f2; min-width: 250px;">Variable</th>' +
          '<th style="padding: 8px; text-align: left; background-color: #f2f2f2; min-width: 200px;">Valor</th>' +
          '<th style="padding: 8px; text-align: left; background-color: #f2f2f2; min-width: 200px;">Hora</th>' +
          '</tr>';
        '</tr>';


        if (selectedReport[0].params && Array.isArray(selectedReport[0].params)) {
          for (const param of selectedReport[0].params) {
            if (param.variables && Array.isArray(param.variables)) {
              for (const variable of param.variables) {
                try {
                  let device = await this.getDeviceData(variable.elementId);
                  console.log(variable)
                  content += '<tr>' +
                    '<td style="padding: 8px; text-align: center;">' + device.label + '</td>' +
                    '<td style="padding: 8px; text-align: center;">' + variable.label + '</td>' +
                    '<td style="padding: 8px; text-align: center;">' + (variable.value ? variable.value : '-') + ' ' + (variable.unit != undefined ? variable.unit : "-") + '</td>' +
                    '<td style="padding: 8px; text-align: center;">' + api.parseDateUserTimezone(variable.valueDateTime, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
                      '</tr>';
                } catch (error) {
                  console.error('Error al obtener datos del dispositivo:', error);
                  content += '<tr>' +
                    '<td style="padding: 8px; text-align: center; color: red;">Error al obtener datos</td>' +
                    '<td style="padding: 8px; text-align: center;">' + variable.label + '</td>' +
                    '<td style="padding: 8px; text-align: center;">' + variable.value + ' ' + variable.unit + '</td>' +
                    '<td style="padding: 8px; text-align: center;">' + variable.valueDateTime + '</td>' +
                    '</tr>';
                }
              }
            }
          }
        } else {
          // Si no hay datos en 'params', mostrar un mensaje de advertencia
          content += '<tr><td colspan="3" style="padding: 8px; text-align: center;">No se encontraron datos.</td></tr>';
        }

        content += '</table>' +
          '</div>' +
          '</td>' +
          '</tr>' +
          '</table>' +
          '</div>';

        row.child(content).show();

        tr.addClass('shown');

      }
      this.setButtonFunctions();
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET 
    async getReportsByCompany() {
      let company = this.currentCompany;
      let response = await api.getReportsList(company)
      this.reportsList = api.parseOrdersFromResponse(response);
    },

    async getDeviceData(id) {
      try {
        let response = await api.getDeviceData(id);
        if (response["status-code"] === "200") {
          let parsed = api.parseDeviceFromResponse(response);
          return parsed;
        }

      } catch (error) {
        console.error(error)
      }

    },

    // TARGET 
    onTargetMachine(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id');
      this.$router.push({ params: { currentDisplay: "machine", targetEntity: this.targetMachineId } });
    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    },
    async refreshView() {
      await this.getReportsByCompany();
      this.displayReportsDataTable();
    }
  },
  async created() {
    await this.getReportsByCompany();
    this.displayReportsDataTable();

  },
  mounted() {
    $("#reportsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: this.fullHeight ? 20 : 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.reportsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[2, 'asc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);

  }
}
</script>

<style>
.btnCopyId:hover,
.btnTargetMachine:hover,
.btnLinkMachine:hover,
.btnUnlinkMachine:hover,
.btnEditMachine:hover,
.btnDeleteMachine:hover {
  color: var(--main-dark) !important;
}

#cardLabel:hover {
  color: var(--main-white) !important;
}

.node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1px 3px !important;
  /* e.g. change 8x to 4px here */
}
</style>